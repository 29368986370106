import styled, { css, keyframes } from 'styled-components/macro';
import { motion } from 'framer-motion';

export const LoaderWrap = styled.div<{
  background?: string;
  position?: string;
  small?: boolean;
  tiny?: boolean;
}>`
  width: ${({ small, tiny }) => (tiny ? 'unset' : small ? 'unset' : '100%')};
  min-height: ${({ small, tiny }) => (tiny ? 'unset' : small ? 0 : '500px')};
  transform: scale(${({ small, tiny }) => (tiny ? 0.2 : small ? 0.3 : 1)});
  height: ${({ small, tiny }) => (tiny ? 'unset' : small ? '0px' : '100%')};
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  ${({ tiny }) =>
    tiny &&
    css`
      margin: -12px -10px;
    `};

  ${(props) =>
    props.position === 'absolute' &&
    css`
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 99;
      min-height: auto;
    `};

  ${(props) =>
    props.position === 'fixed' &&
    css`
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 99;
      min-height: auto;
    `};

  ${(props) =>
    props.background &&
    css`
      background: ${props.background};
    `};
`;

export const InitialLoaderWrap = styled(LoaderWrap)`
  height: 300px;
  min-height: 300px;
`;

export const Rotate = keyframes`
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Logo = styled(motion.div)`
  width: 40px;
  height: 40px;
  position: relative;
  border-radius: 40px;
  background: var(--accent-primary);
  animation-name: ${Rotate};
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    width: 20px;
    height: 20px;
    background: var(--base);
    left: -10px;
    margin-top: -10px;
    border-radius: 20px;
  }
`;
