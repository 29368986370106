import { capitalize } from 'shared/helpers/format';
import {
  Label,
  LabelWrap,
  FilterContent,
  FilterClearButton,
  Checkbox,
} from 'shared/components/FilterAbstract/FilterAbstract';
import { useCallback, useMemo } from 'react';
import { useFilters } from 'shared/hooks/useFilters';
import _ from 'lodash';
import * as S from './styled';
import { StatusProposalType } from 'shared/types';
import { TransactionStatusType } from 'services/Transactions/types';

interface FilterStatusProps extends FiltersProps {
  statuses: (FilterStatusType | StatusProposalType)[];
}

type FilterStatusType = TransactionStatusType | 'PROCESSING';

export const parseStatuses = (
  statuses: (FilterStatusType | StatusProposalType)[] = [],
  action: boolean,
  value: FilterStatusType | StatusProposalType,
) => {
  const clone = _.cloneDeep(statuses);

  if (action) {
    clone.push(value);
  } else {
    const index = clone.findIndex((status) => status === value);
    if (index !== -1) {
      clone.splice(index, 1);
    }
  }

  return clone;
};

interface StatusItemProps {
  onChange: (event: any) => void;
  checked: boolean;
  title: string;
  tab: string;
}

const StatusItem = ({ onChange, checked, title: t, tab }: StatusItemProps) => {
  const title = useMemo(() => t?.toLowerCase(), [t]);
  const status = useMemo(
    () => (['pending', 'sent', 'received']?.includes(t) ? 'processing' : t),
    [t],
  );
  const textTitle = useMemo(() => capitalize(title), [title]);

  return (
    <label>
      <Checkbox
        checked={checked}
        onChange={onChange}
        type="checkbox"
        name="status"
        value={String(title)?.toUpperCase()}
      />

      <S.Cap
        status={status}
        style={{
          color: textTitle === 'Filled' ? 'var(--success)' : 'var(--error)',
        }}
        tab={tab}
      >
        {textTitle}
      </S.Cap>
    </label>
  );
};

export const FilterStatus = ({
  storageKey,
  statuses,
  onSubmit,
}: FilterStatusProps) => {
  const [getFilters, setFilters] = useFilters(storageKey);
  const { status: values = [] } = getFilters();

  const onReset = useCallback(() => {
    setFilters({ status: [] });
    onSubmit();
  }, []);

  const onChange = useCallback(
    (event: any) => {
      const { checked, value } = event.target;
      const { status: values = [] } = getFilters();

      setFilters({
        status: [...parseStatuses(values, checked, value?.toUpperCase())],
      });
      onSubmit();
    },
    [values],
  );

  return (
    <>
      <LabelWrap>
        <Label>Status</Label>
        {values?.length > 0 && (
          <FilterClearButton type="button" onClick={onReset}>
            Clear
          </FilterClearButton>
        )}
      </LabelWrap>
      <FilterContent noPadding>
        <form>
          {statuses.map((status: FilterStatusType | StatusProposalType) => (
            <S.StatusLabel key={`${storageKey}_${status}`}>
              <StatusItem
                checked={values?.includes(status)}
                tab={storageKey}
                title={status}
                onChange={onChange}
              />
            </S.StatusLabel>
          ))}
        </form>
      </FilterContent>
    </>
  );
};
