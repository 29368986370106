import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { format, parseISO } from 'date-fns';

import * as S from '../../styled';
import AppContext from 'shared/contexts/AppContext';
import { Order } from '../../../types';
import { StatusType } from 'shared/types';
import { formatAccount } from 'shared/helpers/format';
import { IconInfo } from '../Filters/styled';
import Tippy from '@tippyjs/react';
import { getExchangeInfoById } from 'shared/constants/exchanges';
import { OrderAccount } from './OrderAccount';

interface OrderExchangeProps {
  exchange: string;
  data: any;
  tab: string;
  columnsSettings: any;
}

export const OrderExchange = ({
  exchange,
  data,
  tab,
  columnsSettings,
}: OrderExchangeProps) => {
  const { appSettings, user, accounts, getAccountById } = useContext(AppContext);

  const exchangeInfo = getExchangeInfoById(exchange);

  return (
    <S.PositionExchangeRow>
      <S.PositionExchangeName>
        <S.PositionExchangeLogo
          style={{ backgroundImage: `url(${exchangeInfo?.logo_url})` }}
        />
        {exchange}
      </S.PositionExchangeName>

      {Object.keys(data).sort((a, b) => a.localeCompare(b)).map((key) => (
        <OrderAccount
          columnsSettings={columnsSettings}
          key={key}
          name={key}
          exchange={exchange}
          orders={data[key]}
          id={data[key]?.[0]?.account?.id}
          tab={tab}
        />
      ))}
    </S.PositionExchangeRow>
  );
};
