import axios, { AxiosRequestConfig } from 'axios';

const formConfig = (config?: AxiosRequestConfig<any>) => {
  return config;
  // return {
  //   ...config,
  //   headers: {
  //     ...config?.headers,
  //     ...(localStorage.getItem('appToken')
  //       ? { authorization: `Bearer ${localStorage.getItem('appToken')}` }
  //       : {}),
  //   },
  // };
};

export const requestGet: typeof axios.get = (url, config) => {
  return axios.get(url, formConfig(config));
};

export const requestPost: typeof axios.post = (url, data, config) => {
  return axios.post(url, data, formConfig(config));
};

export const requestPut: typeof axios.post = (url, data, config) => {
  return axios.put(url, data, formConfig(config));
};

export const requestPatch: typeof axios.post = (url, data, config) => {
  return axios.patch(url, data, formConfig(config));
};
