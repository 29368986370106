import * as S from 'features/Positions/view/styled';
import { useContext } from 'react';
import AppContext from 'shared/contexts/AppContext';
import { FilterSearch } from '../Filters';

import { useFilters } from 'shared/hooks/useFilters';
import { useIsMobile } from 'shared/hooks/useIsMobile';
import queryString from 'query-string';
import { FilterView } from '../Filters/FilterView';
import { FilterType } from '../Filters/FilterType';

interface PositionsFiltersProps {
  onSubmit: (query: string) => void;
  disabled: boolean;
  isMobile: boolean;
  isAsideButtonChecked: string;
  setIsAsideButtonChecked: any;
  setIsMobile: any;
  viewInfo: any;
  setViewInfo: any;
  search: string;
  setSearch: any;
}

const storageKey = 'positions_filters';

export const stringifyUrl = (url: string, filters: any) => {
  return queryString.stringifyUrl(
    {
      url,
      query: {
        ...filters,
      },
    },
    { arrayFormat: 'separator' },
  );
};

const download = function (data: any) {
  const blob = new Blob([data], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');

  a.setAttribute('href', url);
  a.setAttribute('download', 'Multik - Exported Positions.csv');
  a.click();
};

export const PositionsFilters = ({
  onSubmit,
  disabled,
  viewInfo,
  setViewInfo,
  search,
  setSearch,
}: PositionsFiltersProps) => {
  // const [isExporting, toggleExporting] = useToggle(false);
  const { accounts, exchanges, appSettings } = useContext(AppContext);

  const [getFilters] = useFilters('positions_filters');
  const filters = getFilters();
  const [isMobile, isAsideButtonChecked, handleAsideSize] = useIsMobile();

  function countElements(obj: any) {
    let count = 0;

    for (const key in obj) {
      if (Array.isArray(obj[key])) {
        count += obj[key].length;
      } else {
        count++;
      }
    }

    return count;
  }

  const filtersCount = countElements(filters);

  // const onClickExport = async () => {
  //   try {
  //     const filters = getFilters();
  //     const parsedFilters = parseFilters(filters);
  //     toggleExporting(true);
  //     const response = await requestGet(
  //       stringifyUrl('/Positions/download-csv/', parsedFilters),
  //     );
  //     download(response.data);
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     toggleExporting(false);
  //   }
  // };

  return (
    <S.Filters isMobile={isMobile} isAsideButtonChecked={isAsideButtonChecked}>
      {isMobile && (
        <S.FiltersData onClick={handleAsideSize} isMobile={isMobile}>
          <S.FiltersCounter>{filtersCount}</S.FiltersCounter>
          <S.FiltersIcon />
        </S.FiltersData>
      )}
      <S.Filter isMobile={isMobile}>
        <FilterSearch
          storageKey={storageKey}
          // @ts-ignore
          onSubmit={onSubmit}
          search={search}
          setSearch={setSearch}
          disabled={disabled}
        />
      </S.Filter>
      <S.Filter isMobile={isMobile}>
        <FilterView
          viewInfo={viewInfo}
          setViewInfo={setViewInfo}
        />
      </S.Filter>
      <S.Filter isMobile={isMobile}>
        <FilterType
          // @ts-ignore
          onSubmit={onSubmit}
          forceOpen={true}
          disabled={disabled}
          storageKey={storageKey}
          label="Market"
          entity="Market"
          field="instrument_type"
          entities={[
            {
              id: 'MARGIN',
              name: 'Margin',
            },
            {
              id: 'FUTURE',
              name: 'Future',
            },
            {
              id: 'OPTION',
              name: 'Option',
            },
          ]}
          fieldIdFn={(item: any) => item.id}
          fieldNameFn={(item: any) => item.name}
        />
      </S.Filter>
      {/* TOOD: add currency view */}
      {/* <S.FilterDivider></S.FilterDivider>
      <S.Filter isMobile={isMobile}>
        <div style={{ width: 'fit-content' }}>
          <S.ExportButton disabled={isExporting} onClick={onClickExport}>
            {!isExporting ? (
              'Export as CSV'
            ) : (
              <>
                Exporting
                <Loader small={true} position="relative" />
              </>
            )}
          </S.ExportButton>
        </div>
      </S.Filter> */}
      <S.AsideButton
        onClick={handleAsideSize}
        isMobile={isMobile}
        isAsideButtonChecked={isAsideButtonChecked}
      >
        <S.ArrowIconAside />
      </S.AsideButton>
    </S.Filters>
  );
};
