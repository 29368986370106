import { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';

import * as S from '../styled';

import AppContext from 'shared/contexts/AppContext';

import { EditUserModal } from './EditUserModal';
import { useGetUsers } from '../hooks/useGetUsers';
import { UsersTableProps } from '../types';
import { Input, ProtectedArea } from 'shared/components';
import { Loader as InlineLoader } from 'shared/components/Loader';
import styled from 'styled-components';
import useOnClickOutside from 'use-onclickoutside';
import { useFilters } from 'shared/hooks/useFilters';
import { useDebounce } from 'shared/hooks/useDebounce';
import { formatAmount } from 'features/SafeApp/helpers/formatNumber';
import { capitalize } from 'shared/helpers/format';
import Tippy from '@tippyjs/react';
const InlineLoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface SelectProps {
  label: string;
  values: any[];
  onChange: any;
}

interface SearchBarProps {
  search: string;
  setSearch: any;
  refetchUsers: any;
}

export const SearchBar: FC<SearchBarProps> = ({
  search,
  setSearch,
  refetchUsers,
}) => {
  const [_, setFilters] = useFilters('users_settings_search');
  const onChange = (e: any) => {
    setSearch(e.target.value);
  };

  useDebounce(
    () => {
      setFilters({ search });
      refetchUsers();
    },
    [search],
    600,
  );

  const onReset = () => {
    setSearch('');
  };

  return (
    <S.SearchForm>
      <S.IconSearch />
      <S.SearchInput placeholder="Search" value={search} onChange={onChange} />
      {search && <S.IconClear onClick={onReset} />}
    </S.SearchForm>
  );
};

const Select = ({ label, values, onChange }: SelectProps) => {
  const selectRef = useRef(null);
  useOnClickOutside(selectRef, () => handleClose());
  const [currentValue, setCurrentValue] = useState('');
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleValueChange = (value: any) => {
    setCurrentValue(value);
  };
  const handleChange = (value: any) => {
    handleValueChange(value);
    if (onChange) onChange(value);
    handleClose();
  };
  return (
    <S.SelectContainer ref={selectRef}>
      <S.SelectLabelButton onClick={handleOpen}>
        {currentValue !== '' ? currentValue : label}
      </S.SelectLabelButton>
      <S.DropdownStyle isVisible={open}>
        {values.map((value, index) => (
          <S.DropdownItem
            onClick={() => handleChange(value)}
            active={value === currentValue}
            key={index}
          >
            {value}
          </S.DropdownItem>
        ))}
      </S.DropdownStyle>
      <S.SelectDropdownIcon />
    </S.SelectContainer>
  );
};

const USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});

const GroupRowItem = ({ item }: any) => {
  const { openModal, user, appSettings } = useContext(AppContext);
  const isCritical =
    100 -
      (Number(item.payment_limit?.payments) /
        Number(item.payment_limit?.limit)) *
        100 <=
    10;

  const onEdit = () => {
    openModal({
      title: 'Edit user',
      width: '406px',
      outsideClickClose: false,
      titleRightText: 'Step 1 of 2',
      component: () => (
        <EditUserModal
          id={item.id}
          email={item.email}
          payments={item?.payment_limit?.payments}
          currentValues={{
            period_type: item.payment_limit.period,
            single_transaction_limit: String(
              parseInt(item.single_transaction_limit),
            ),
            vote_weight: item.vote_weight,
            period_limit: String(parseInt(item.payment_limit.limit)),
          }}
        />
      ),
    });
  };

  return (
    <S.GroupsRow>
      <S.GroupsCol>
        <Tippy
          theme="transparent"
          trigger="mouseenter"
          placement="top"
          content={`${item.email} ${
            user?.email === item?.email ? '(You)' : ''
          }`}
        >
          <div
            style={{
              width: '100%',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            {item.email} {user?.email === item?.email ? '(You)' : ''}
          </div>
        </Tippy>
      </S.GroupsCol>
      <S.GroupsCol>
        {item.vote_weight
          ? `${item.vote_weight} vote${item.vote_weight > 1 ? 's' : ''}`
          : 'Unset'}
      </S.GroupsCol>
      <S.GroupsCol>
        <Tippy
          theme="transparent"
          trigger="mouseenter"
          placement="top"
          content={`${
            item?.single_transaction_limit
              ? USDollar.format(item.single_transaction_limit)
              : `Default (${USDollar.format(
                  Number(appSettings?.user_single_transaction_limit_default),
                )})`
          }`}
        >
          <div
            style={{
              width: '100%',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            {item?.single_transaction_limit
              ? USDollar.format(item.single_transaction_limit)
              : `Default (${USDollar.format(
                  Number(appSettings?.user_single_transaction_limit_default),
                )})`}
          </div>
        </Tippy>
      </S.GroupsCol>
      <S.GroupsCol>
        <Tippy
          theme="transparent"
          trigger="mouseenter"
          placement="top"
          content={`${USDollar.format(item.payment_limit?.limit)} /${' '}
          ${capitalize(item.payment_limit?.period)}`}
        >
          <div
            style={{
              width: '100%',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            {USDollar.format(item.payment_limit?.limit)} /{' '}
            {capitalize(item.payment_limit?.period)}
          </div>
        </Tippy>
      </S.GroupsCol>
      <S.GroupsCol
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          position: 'relative',
          minWidth: '145px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            position: 'absolute',
            top: '-8px',
            height: '22px',
            paddingRight: '20px',
          }}
        >
          <div>{USDollar.format(item.payment_limit?.payments)}</div>
          <div
            style={{
              position: 'relative',
              height: '2px',
              borderRadius: '2px',
              width: '100%',
              minWidth: '90px',
              background: 'var(--placeholder)',
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: '0px',
                left: '0px',
                height: '2px',
                borderRadius: '2px',
                width: `${
                  (Number(item.payment_limit?.payments) /
                    Number(item.payment_limit?.limit) >
                  1
                    ? 1
                    : Number(item.payment_limit?.payments) /
                      Number(item.payment_limit?.limit)) * 100
                }%`,
                background: isCritical
                  ? 'linear-gradient(90deg, #0064FF 75%, #E64022 100%)'
                  : 'var(--accent-primary)',
              }}
            ></div>
          </div>
        </div>
        <div
          style={{
            position: 'absolute',
            cursor: 'pointer',
            right: '0px',
            top: '-4px',
            borderRadius: '10px',
            background: 'var(--accent-primary)',
            padding: '4px 8px',
            color: 'var(--dark-bg)',
            fontFamily: 'var(--font-main)',
            textTransform: 'uppercase',
            fontSize: '9px',
            fontWeight: 'bold',
          }}
          onClick={onEdit}
        >
          Edit
        </div>
      </S.GroupsCol>
    </S.GroupsRow>
  );
};

export const UsersTable: FC<UsersTableProps> = ({ title }) => {
  const { openModal, user } = useContext(AppContext);

  const selectRef = useRef(null);

  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState('');
  const [count, setCount] = useState(0);
  const [pages, setPages] = useState(Math.ceil(count / limit));
  const [page, setPage] = useState(1);

  const { data, isLoading, isFetching, refetch } = useGetUsers({
    limit,
    offset: (page - 1) * limit,
    search,
  });

  const users = data?.results;

  useEffect(() => {
    setPage(1);
  }, [search]);

  useEffect(() => {
    setCount(data?.count);
  }, [data?.count]);

  useEffect(() => {
    if (!count || !limit) return;
    setPages(Math.ceil(count / limit));
  }, [limit, count]);

  const onChangeLimit = (v: any) => {
    setLimit(v);
    setPage(1);
  };

  const onPrevPage = () => {
    if (page <= 1) return;
    setPage(page - 1);
  };

  const onNextPage = () => {
    if (page >= pages) return;
    setPage(page + 1);
  };

  const onSetPage = (page: any) => {
    setPage(page);
  };

  const onSetInputPage = (e: any) => {
    const page = parseInt(e.target.value);
    if (isNaN(page)) return;
    if (page <= 0) {
      setPage(1);
    } else if (page > pages) {
      setPage(pages);
    } else {
      setPage(page);
    }
  };

  const formArray = (l: number) =>
    Array.from(Array(l).keys()).map((p) => p + 1);

  const formArrayFrom = (l: number, from: number) =>
    Array.from(Array(l).keys()).map((p) => p + from);

  const getPagesList = () => {
    const arr = formArray(pages);

    if (arr.length <= 7) return arr;

    const modified = [];

    if (page <= 4) {
      modified.push(...formArray(5));
    } else if (page >= pages - 4) {
      modified.push(1, '...');
    } else {
      modified.push(1, '...', ...formArrayFrom(3, page - 1));
    }

    if (page <= 4) {
      modified.push('...', pages);
    } else if (page >= pages - 4) {
      if (page >= pages - 3) {
        modified.push(...formArrayFrom(5, pages - 4));
      } else {
        modified.push(...formArrayFrom(3, page - 1), '...', pages);
      }
    } else {
      modified.push('...', pages);
    }

    return modified;
  };

  const pagesList = getPagesList();
  const isEmpty = (!users || users?.length === 0) && !isLoading && !isFetching;

  return (
    <S.UsersTableWrapper
      isEmpty={isEmpty}
      hasGroups={users?.length > 0}
      limit={users?.length}
    >
      <S.CardHeader>
        <S.CardHeaderText>
          <S.IconUsers />
          Users
        </S.CardHeaderText>
        <S.CardSetWrapper>
          <SearchBar
            search={search}
            refetchUsers={refetch}
            setSearch={setSearch}
          />
        </S.CardSetWrapper>
      </S.CardHeader>
      {!isEmpty ? (
        <S.GroupsBody>
          {(users?.length || isLoading || isFetching) && (
            <S.GroupsList limit={users?.length}>
              <S.GroupsHeader>
                <S.GroupsCol>user name</S.GroupsCol>
                <S.GroupsCol>vote weight</S.GroupsCol>
                <S.GroupsCol>single transaction limit</S.GroupsCol>
                <S.GroupsCol>payment limit</S.GroupsCol>
                <S.GroupsCol>spent payment limit</S.GroupsCol>
              </S.GroupsHeader>
              {isLoading || isFetching ? (
                <InlineLoaderWrapper>
                  <InlineLoader small={true} />
                </InlineLoaderWrapper>
              ) : (
                users?.map((item: any) => (
                  <GroupRowItem key={item.id} item={item} />
                ))
              )}
            </S.GroupsList>
          )}
          <S.SelectWrap>
            <Select
              label={String(limit)}
              values={[5, 10, 15]}
              onChange={onChangeLimit}
            />
            &nbsp;&nbsp;&nbsp;&nbsp;Lines per page
          </S.SelectWrap>
          <S.PagesButtons pages={pages}>
            <S.PrevButton inactive={page <= 1} onClick={onPrevPage} />
            <S.PagesList>
              {pagesList?.map((p) => {
                const onChangePage = () => {
                  if (p === '...') return;
                  onSetPage(p);
                };
                return (
                  <S.PageButton selected={page === p} onClick={onChangePage}>
                    {p}
                  </S.PageButton>
                );
              })}
            </S.PagesList>
            <S.NextButton inactive={page >= pages} onClick={onNextPage} />
          </S.PagesButtons>
          <S.GoToPage disabled={pages === 1}>
            Go to page
            <S.StyledInput
              placeholder="#"
              onChange={onSetInputPage}
              type="number"
              value={page}
              disabled={pages === 1}
            />
          </S.GoToPage>
        </S.GroupsBody>
      ) : (
        <div style={{ fontSize: '12px', marginBottom: '16px' }}>
          No users found matching your search criteria.
        </div>
      )}
    </S.UsersTableWrapper>
  );
};
