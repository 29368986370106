import styled from 'styled-components/macro';
import { motion } from 'framer-motion';
import { ReactComponent as SvgGoogle } from '../../assets/icons/icon-google.svg';
import { ReactComponent as SvgLogo } from '../../assets/icons/views/logo-multik.svg';
import { ReactComponent as SvgArrow } from 'shared/assets/icons/icon-dropdown-small.svg';

export const LoginWrap = styled(motion.div)`
  width: 100%;
  // height: 100%;
  // display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1001;

  // background: #00143c;

  // --object-primary: var(--dark-bg);
  // --base: #00143c;
  // --accent-secondary: #c91cff;
`;

export const LoginButton = styled.a`
  display: flex;
  align-items: center;
  padding: 10px;
  line-height: 20px;
  font-size: 12px;
  line-height: 14px;
  width: 100%;
  height: 40px;
  border-radius: 40px;
  background: var(--object-primary);
  color: var(--base);
  font-family: Lato;
  font-weight: 700;
  text-transform: uppercase;
`;

export const IconLogo = styled(SvgLogo)``;

export const IconGoogle = styled(SvgGoogle)`
  width: 15px;
  height: 15px;
  margin: 0 10px 0 2px;
`;

export const IconArrow = styled(SvgArrow)`
  width: 10px;
  height: 10px;
`;

export const LogoWrap = styled(motion.div)`
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  // height: 120px;
`;
