import { GoogleLogin } from '@react-oauth/google';
import { useState } from 'react';
import { IconGoogle } from './styled';

interface ButtonProps {
  loading?: boolean;
  onSuccess: (response: any) => void;
  text: string;
}

export default function GoogleAuthButton({
  loading = false,
  onSuccess,
  text,
}: ButtonProps) {
  const [error, setError] = useState('');

  const buttonStyle: React.CSSProperties = {
    pointerEvents: loading ? 'none' : 'auto',
    borderRadius: '9999px',
    display: 'flex',
    position: 'relative',
    cursor: 'pointer',
    border: '1px solid var(--border)',
    backgroundColor: 'transparent',
    fontSize: '12px',
    fontWeight: 'bold',
    color: 'var(--primary)',
    justifyContent: 'space-between',
    alignItems: 'center',
    textTransform: 'uppercase',
    padding: '0.5rem 0.75rem',
  };

  const googleLoginStyle: React.CSSProperties = {
    position: 'absolute',
    width: '254px',
    top: '-4px',
    left: '0',
    opacity: '0',
  };

  const iconContainerStyle: React.CSSProperties = {
    width: '1.5rem',
  };

  const loadingIconStyle: React.CSSProperties = {
    marginLeft: '0.5rem',
    animation: 'spin 1s linear infinite',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const errorStyle: React.CSSProperties = {
    fontSize: '12px',
    color: 'var(--error)',
    marginTop: '0.25rem',
  };

  return (
    <div>
      <div style={buttonStyle}>
        <div style={googleLoginStyle}>
          <GoogleLogin onSuccess={onSuccess} />
        </div>
        <div style={iconContainerStyle}><IconGoogle /></div>
        <div>{text}</div>
        <div style={iconContainerStyle}>
          {loading && (
            <div style={loadingIconStyle}>{/* Loading icon removed */}</div>
          )}
        </div>
      </div>
      <div style={errorStyle}>{error}</div>
    </div>
  );
}
