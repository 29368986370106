/* eslint-disable */
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { format, parseISO } from 'date-fns';

import * as S from '../../styled';
import AppContext from 'shared/contexts/AppContext';
import { Position } from '../../../types';
import { StatusType } from 'shared/types';
import {
  _removeTrailingZeros,
  formatAccount,
  formatNumberWithoutExponent,
} from 'shared/helpers/format';
import { IconInfo } from '../Filters/styled';
import Tippy from '@tippyjs/react';
import { CurrenciesStoreInstance } from 'services';
import Decimal from 'decimal.js-light';
import { MAX_INTEGER } from 'ethereumjs-util';

interface PositionRowProps {
  position: Position;
  viewInfo: any;
  columnsSettings: any;
}

export const PositionRow = ({
  position,
  viewInfo,
  columnsSettings,
}: PositionRowProps) => {
  const { rates } = useContext(AppContext);
  const { getCurrencyPrecision, getCurrencyValue } = CurrenciesStoreInstance;

  const {
    external_id,
    pair,
    type,
    size,
    price_entry,
    time_entry,
    origin_funding,
    origin_pnl_unrealized,
    mark_price,
    pnl_currency,
    instrument_type,
    pnl_realized,
    pnl_unrealized,
    updated_at,
    size_type,
    price_currency,
  } = position;

  const timeEntryDate = time_entry
    ? format(parseISO(time_entry), 'yyyy-MM-dd')
    : '-';
  const timeEntryTime = time_entry
    ? format(parseISO(time_entry), 'HH:mm:ss')
    : '-';

  const updateTimeDate = updated_at
    ? format(parseISO(updated_at), 'yyyy-MM-dd')
    : '-';
  const updateTimeTime = updated_at
    ? format(parseISO(updated_at), 'HH:mm:ss')
    : '-';

  return (
    <S.Row
      key={external_id}
      data-id={external_id}
      style={{ paddingRight: '35px' }}
    >
      <S.PositionIntro>
        {columnsSettings.activeColumns.includes('market_pair') && (
          <S.DateCol>{pair}</S.DateCol>
        )}
        {columnsSettings.activeColumns.includes('instrument_type') && (
          <S.DateCol>{instrument_type}</S.DateCol>
        )}
        {columnsSettings.activeColumns.includes('type') && (
          <S.TxCol>
            {type === 'LONG' ? <S.IconLong /> : <S.IconShort />}
            {type}
          </S.TxCol>
        )}
        {columnsSettings.activeColumns.includes('size') && (
          <S.FromCol>
            {Number(size) >= 1 && Number(size) <= -1
              ? parseInt(size)
              : _removeTrailingZeros(size)}
            {size_type === 'CONTRACTS' ? ` contract` : ''}
          </S.FromCol>
        )}
        {columnsSettings.activeColumns.includes('current_value') && (
          <S.StatusCol>
            {mark_price
              ? getCurrencyValue({
                  // @ts-ignore
                  currency: viewInfo?.id,
                  value: new Decimal(size)
                    .mul(mark_price)
                    .mul(
                      viewInfo.currency === 'EUR'
                        ? rates?.[viewInfo?.currency]?.['USD']
                        : 1,
                    )
                    .div(viewInfo?.divisor)
                    .toFixed(),
                  // @ts-ignore
                  minprecision: getCurrencyPrecision(viewInfo?.id),
                })
              : getCurrencyValue({
                  // @ts-ignore
                  currency: viewInfo?.id,
                  value: new Decimal(size)
                    .mul(price_entry)
                    .plus(origin_pnl_unrealized)
                    .mul(rates?.[viewInfo?.currency]?.['USD'])
                    .div(viewInfo?.divisor)
                    .toFixed(),
                  // @ts-ignore
                  minprecision: getCurrencyPrecision(viewInfo?.id),
                })}
            {viewInfo?.divisor_label} {viewInfo?.currency}
          </S.StatusCol>
        )}
        {columnsSettings.activeColumns.includes('price_entry') && (
          <S.ToCol>
            {_removeTrailingZeros(
              parseFloat(price_entry).toLocaleString('en-US', {
                minimumFractionDigits: 20,
              }),
            )}{' '}
            {price_currency}
          </S.ToCol>
        )}
        {columnsSettings.activeColumns.includes('opening_time') && (
          <S.AmountCol>
            {time_entry ? (
              <>
                <div>{timeEntryDate}</div>
                <span style={{ color: 'var(--object-secondary)' }}>
                  {timeEntryTime}
                </span>{' '}
              </>
            ) : (
              <div style={{ paddingLeft: '26px' }}>-</div>
            )}
          </S.AmountCol>
        )}
        {columnsSettings.activeColumns.includes('updated_at') && (
          <S.AmountCol>
            {updated_at ? (
              <>
                <div>{updateTimeDate}</div>
                <span style={{ color: 'var(--object-secondary)' }}>
                  {updateTimeTime}
                </span>{' '}
              </>
            ) : (
              <div style={{ paddingLeft: '26px' }}>-</div>
            )}
          </S.AmountCol>
        )}
        {columnsSettings.activeColumns.includes('accumulated_funding') && (
          <S.ConfirmationsCol
            style={{
              minWidth: '130px',
              color:
                Number(origin_funding) < 0 ? 'var(--error)' : 'var(--success)',
            }}
          >
            {getCurrencyValue({
              // @ts-ignore
              currency: pnl_currency,
              value: new Decimal(String(Number(origin_funding))).toFixed(),
              // @ts-ignore
              minprecision: getCurrencyPrecision(pnl_currency),
            })}{' '}
            {pnl_currency}
          </S.ConfirmationsCol>
        )}
        {columnsSettings.activeColumns.includes('pnl_realized') && (
          <S.ConfirmationsCol
            style={{
              color:
                Number(pnl_realized) < 0 ? 'var(--error)' : 'var(--success)',
            }}
          >
            {getCurrencyValue({
              // @ts-ignore
              currency: viewInfo?.id,
              value: new Decimal(pnl_realized)
                .mul(rates?.[viewInfo?.currency]?.['USD'])
                .div(viewInfo?.divisor)
                .toFixed(),
              // @ts-ignore
              minprecision: getCurrencyPrecision(viewInfo?.id),
            })}
            {viewInfo?.divisor_label} {viewInfo?.currency}
          </S.ConfirmationsCol>
        )}
        {columnsSettings.activeColumns.includes('pnl_unrealized') && (
          <S.ConfirmationsCol
            style={{
              color:
                Number(pnl_unrealized) < 0 ? 'var(--error)' : 'var(--success)',
            }}
          >
            {getCurrencyValue({
              // @ts-ignore
              currency: viewInfo?.id,
              value: new Decimal(pnl_unrealized)
                .mul(rates?.[viewInfo?.currency]?.['USD'])
                .div(viewInfo?.divisor)
                .toFixed(),
              // @ts-ignore
              minprecision: getCurrencyPrecision(viewInfo?.id),
            })}
            {viewInfo?.divisor_label} {viewInfo?.currency}
          </S.ConfirmationsCol>
        )}
      </S.PositionIntro>
    </S.Row>
  );
};
