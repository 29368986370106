import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { format, parseISO } from 'date-fns';

import * as S from '../../styled';
import AppContext from 'shared/contexts/AppContext';
import { Position } from '../../../types';
import { StatusType } from 'shared/types';
import { formatAccount } from 'shared/helpers/format';
import { IconInfo } from '../Filters/styled';
import Tippy from '@tippyjs/react';
import { getExchangeInfoById } from 'shared/constants/exchanges';
import { PositionRow } from './PositionRow';
import {
  IconArrow,
  IconArrowWrap,
} from 'features/Transfer/view/components/ExchangeAbstract';
import { useToggle } from 'shared/hooks/useToggle';
import Decimal from 'decimal.js-light';
import { isUndefined } from 'shared/helpers/strings';
import { CurrenciesStoreInstance } from 'services/Currencies/mobx/CurrenciesStore';
import { IconInfoRaw } from 'features/Orders/view/components/Filters/styled';
import { IconCheck } from 'features/Transactions/view/components/shared/ManualResolve';
import { Loader } from 'shared/components';

interface PositionAccountProps {
  name: string;
  exchange: string;
  positions: any[];
  viewInfo: any;
  columnsSettings: any;
  id: string;
}

export const PositionAccount = ({
  name,
  exchange,
  positions,
  viewInfo,
  columnsSettings,
  id,
}: PositionAccountProps) => {
  const { appSettings, user, accounts, getAccountById, isAccountsLoading } =
    useContext(AppContext);
  const [isOpen, toggleIsOpen] = useToggle(true);
  const { rates, getCurrencyValue } = CurrenciesStoreInstance;

  const account = getAccountById(Number(id));
  const exchangeInfo = getExchangeInfoById(exchange);
  const balanceUpdatedAt = account?.balance_updated_at;
  const balanceUpdatedAtFormatted = balanceUpdatedAt
    ? format(parseISO(balanceUpdatedAt), 'HH:mm dd.MM.yyyy') + ' UTC'
    : null;
  const isBalanceOutdated = balanceUpdatedAt
    ? new Date().getTime() - new Date(balanceUpdatedAt).getTime() >
      24 * 60 * 60 * 1000
    : true;
  const isBalanceOutdatedTooltip = isBalanceOutdated
    ? `The account has not been updated for more than a day. Please check the API keys. 
Last update time: ${balanceUpdatedAtFormatted}`
    : `Updated at ${balanceUpdatedAtFormatted}`;
  const total = account?.wallets?.reduce(
    (acc: any, { total, currency }: any) => {
      const multiplier =
        rates?.USD && !isUndefined(rates?.USD[currency])
          ? rates?.USD[currency]
          : new Decimal(0);
      return new Decimal(acc).plus(new Decimal(total).mul(multiplier));
    },
    new Decimal(0),
  );
  const available = account?.wallets?.reduce(
    (acc: any, { available, currency }: any) => {
      const multiplier =
        rates?.USD && !isUndefined(rates?.USD[currency])
          ? rates?.USD[currency]
          : new Decimal(0);
      return new Decimal(acc).plus(new Decimal(available).mul(multiplier));
    },
    new Decimal(0),
  );

  const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
    toggleIsOpen();
    e.stopPropagation();
  };

  return (
    <S.PositionAccountRow>
      <S.PositionAccountName onClick={onClick}>
        <IconArrowWrap rotateIcon={isOpen}>
          <IconArrow />
        </IconArrowWrap>
        <S.PositionAccountExchangeLogo
          style={{ backgroundImage: `url(${exchangeInfo?.logo_url})` }}
        />
        {name}
        {isAccountsLoading && <Loader tiny />}
        {balanceUpdatedAt && (
          <Tippy
            trigger="mouseenter"
            theme="transparent"
            placement="top"
            content={isBalanceOutdatedTooltip}
          >
            {isBalanceOutdated ? (
              <IconInfoRaw style={{ margin: '0 6px' }} />
            ) : (
              <IconCheck style={{ margin: '0 6px' }} />
            )}
          </Tippy>
        )}
        {balanceUpdatedAt && (
          <>
            <div
              style={{
                marginRight: '6px',
                fontFamily: 'var(--font-monospaced)',
                fontWeight: 'normal',
                fontSize: '10px',
                color: 'var(--border)',
              }}
            >
              /
            </div>
            <div
              style={{
                fontFamily: 'var(--font-monospaced)',
                fontWeight: 'normal',
                fontSize: '10px',
              }}
            >
              $
              {parseFloat(total?.toString() || '0').toLocaleString('en-US', {
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
              })}
              <div
                style={{ display: 'inline', color: 'var(--object-secondary)' }}
              >{` ($${parseFloat(available?.toString() || '0').toLocaleString('en-US', {
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
              })})`}</div>
            </div>
          </>
        )}
      </S.PositionAccountName>
      {isOpen &&
        positions?.map((position) => (
          <PositionRow
            viewInfo={viewInfo}
            key={position.external_id}
            position={position}
            columnsSettings={columnsSettings}
          />
        ))}
    </S.PositionAccountRow>
  );
};
