import { action, makeObservable } from 'mobx';

import {
  AccountsStoreInstance,
  CurrenciesStoreInstance,
  SettingsStoreInstance,
  UserStoreInstance,
} from 'services';

import { IAppStore } from '../models';

class AppStore implements IAppStore {
  interval?: number;
  isSyncing = false;

  constructor() {
    makeObservable(this);
  }

  @action.bound public async initApplication(openModal: any) {
    if (!SettingsStoreInstance || !UserStoreInstance) {
      return;
    }
    await UserStoreInstance.fetchUserInfo();

    if (UserStoreInstance?.user) {
      if (
        SettingsStoreInstance.appSettings.is_hotp_enabled &&
        !UserStoreInstance.user.has_hotp_secret
      ) {
        openModal();
      }
      void AccountsStoreInstance.fetchAccounts();
      void AccountsStoreInstance.fetchExchangesStatuses();
      void CurrenciesStoreInstance.fetchRates();
      this.syncData();
    }
  }

  public refetchData() {
    void AccountsStoreInstance.fetchAccounts(true);
    void AccountsStoreInstance.fetchExchangesStatuses();
    void CurrenciesStoreInstance.fetchRates();
  }

  @action.bound public stopSync() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = undefined;
    }
  }

  public syncData() {
    if (this.isSyncing) {
      return;
    }
    this.isSyncing = true;
    this.interval = setInterval(() => {
      this.refetchData();
    }, 15000);
  }
}

export const AppStoreInstance = new AppStore();
