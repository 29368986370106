import { FC, useContext, useEffect, useRef, useState } from 'react';
import { CodeInput, digitsNumber, SubmitButton } from './shared';

import * as S from '../Modal/styled';
import { parseError } from 'shared/helpers/errors';
import AppContext from 'shared/contexts/AppContext';

interface LoginOtpProps {
  googleToken?: any;
  email?: string;
  password?: string;
  fetchToken: any;
}

export const LoginOtp: FC<LoginOtpProps> = ({ googleToken, email, password, fetchToken }) => {
  const { appSettings } = useContext(AppContext);
  const [otp, setOtp] = useState('');
  const [errorText, setErrorText] = useState<string | null>(null);
  const [isSubmit, setSubmit] = useState(false);
  const timerRef = useRef<number>(-1);
  const codeRef = useRef(null);

  const onChange = (vals: any) => {
    setOtp(vals);
    setSubmit(false);
    setErrorText('');
  };

  useEffect(() => {
    window.clearTimeout(timerRef.current);
    timerRef.current = window.setTimeout(() => {
      if (otp.length >= digitsNumber) {
        onSubmit();
      }
    }, 900);
  }, [otp]);

  const onSubmit = async () => {
    try {
      setSubmit(true);
      if (googleToken) {
        await fetchToken(googleToken, otp);
        if (appSettings.is_hotp_enabled) {
          window.location.href = '/';
        }
      } else {
        await fetchToken(email, password, undefined, otp);
      }
    } catch (error) {
      setErrorText(parseError(error, appSettings));
    } finally {
      setSubmit(false);
    }
  };

  const onReset = () => {
    onChange('');
  };

  const disabled = isSubmit || otp.length < digitsNumber;

  return (
    <>
      <S.ModalText>Enter the 6-digit code generated by your authentication app.</S.ModalText>
      <CodeInput
        value={otp}
        ref={codeRef}
        title="Multik Authenticator code"
        onChange={onChange}
        onReset={onReset}
        errorText={errorText}
      />
      {errorText && <S.ModalError data-id="otp-error">{errorText}</S.ModalError>}
      <S.ModalDiv />
      <S.ConfirmButton disabled={disabled} type="button" onClick={onSubmit}>
        <SubmitButton isSubmit={isSubmit} errorText={errorText} />
      </S.ConfirmButton>
    </>
  );
};
