import { List } from '../shared/List';
import { NotFound } from '../shared/NotFound';
import { TransactionStatusModal } from 'shared/components/TransactionStatusModal';
import { useContext } from 'react';
import AppContext from 'shared/contexts/AppContext';
import { TransactionRow } from './TransactionRow';

export const TransactionsQueue = ({ query }: any) => {
  const {
    rows,
    isLoading,
    isInitialLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = query;

  const { isAccountsLoading } = useContext(AppContext);

  return (
    <>
      <List
        isFetching={isFetchingNextPage || isAccountsLoading}
        isInitialLoading={isInitialLoading || isAccountsLoading}
        isLoading={isLoading}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        row={TransactionRow}
        data={rows}
        infiniteScroll={false}
        paddingBottom={Boolean(rows?.length)}
      />
      <NotFound
        shrinked={true}
        paddingTop={Boolean(rows?.length)}
        active={!isLoading && !rows?.length}
      >
        No queued transactions found
      </NotFound>
    </>
  );
};
