import { useQuery } from '@tanstack/react-query';
import { requestGet } from 'shared/axios';

export const useGetUsers = ({ limit, offset, search }: any) => {
  return useQuery({
    queryKey: ['users', limit, offset],
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const response = await requestGet('/administration/users/', {
        params: { limit, offset, search },
      });
      return response.data;
    },
  });
};
