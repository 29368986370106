import { Orders } from 'features/Orders/view/components/Orders';
import { ProtectedArea } from 'shared/components';

export const OrdersPage = () => {
  return (
    <ProtectedArea displayMessage every={['accounts.view_account']}>
      <Orders />
    </ProtectedArea>
  );
};
