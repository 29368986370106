/* eslint-disable */
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { format, parseISO } from 'date-fns';

import * as S from '../../styled';
import AppContext from 'shared/contexts/AppContext';
import { Order } from '../../../types';
import { StatusType } from 'shared/types';
import {
  _removeTrailingZeros,
  capitalize,
  formatAccount,
  formatNumberWithoutExponent,
} from 'shared/helpers/format';
import { IconInfo } from '../Filters/styled';
import Tippy from '@tippyjs/react';
import { CurrenciesStoreInstance } from 'services';
import Decimal from 'decimal.js-light';

interface OrderRowProps {
  order: Order;
  tab: string;
  columnsSettings: any;
}

const USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});

export const OrderRow = ({ order, tab, columnsSettings }: OrderRowProps) => {
  const { rates } = useContext(AppContext);
  const { getCurrencyPrecision, getCurrencyValue } = CurrenciesStoreInstance;

  const {
    account,
    external_id,
    pair,
    side,
    type,
    status,
    is_active,
    price,
    size,
    time_created,
    time_closed,
    trigger_price,
    currency,
    filled,
  } = order;
  const { name: accountName, exchange: accountExchange } = account;

  const timeCreatedDate = time_created
    ? format(parseISO(time_created), 'yyyy-MM-dd')
    : '-';
  const timeCreatedTime = time_created
    ? format(parseISO(time_created), 'HH:mm:ss')
    : '-';

  const executionTimeDate = time_closed
    ? format(parseISO(time_closed), 'yyyy-MM-dd')
    : '-';
  const executionTimeTime = time_closed
    ? format(parseISO(time_closed), 'HH:mm:ss')
    : '-';

  return (
    <S.Row
      key={external_id}
      data-id={external_id}
      style={{ paddingRight: '35px' }}
    >
      <S.PositionIntro>
        {columnsSettings.activeColumns.includes('market_pair') && (
          <S.DateCol>{pair.replace(/_/g, '-')}</S.DateCol>
        )}
        {columnsSettings.activeColumns.includes('side') && (
          <S.TxCol
            style={{
              fontWeight: 'bold',
              color: side === 'SELL' ? 'var(--error)' : 'var(--success)',
            }}
          >
            {side}
          </S.TxCol>
        )}
        {columnsSettings.activeColumns.includes('type') && (
          <S.FromCol>{capitalize(type.replace(/_/g, ' '))}</S.FromCol>
        )}
        {columnsSettings.activeColumns.includes('size_|_filled') && (
          <S.StatusCol style={{ textAlign: 'left' }}>
            {Number(size) >= 1 && Number(size) <= -1
              ? parseInt(size)
              : _removeTrailingZeros(size)}
            {currency && currency !== null && ` ${currency}`}
            {filled ? (
              <div style={{ color: 'var(--object-secondary)' }}>
                {Number(filled) >= 1 && Number(filled) <= -1
                  ? parseInt(filled)
                  : _removeTrailingZeros(filled)}
                {currency && currency !== null && ` ${currency}`}
              </div>
            ) : (
              <div style={{ color: 'var(--object-secondary)' }}>-</div>
            )}
          </S.StatusCol>
        )}
        {columnsSettings.activeColumns.includes('price') && (
          <S.StatusCol style={{ textAlign: 'left' }}>
            {trigger_price && price === null ? (
              <>
                {_removeTrailingZeros(
                  parseFloat(trigger_price).toLocaleString('en-US', {
                    minimumFractionDigits: 20,
                  }),
                )}{' '}
                USD
                <div style={{ color: 'var(--object-secondary)' }}>
                  Trigger price
                </div>
              </>
            ) : price === null ? (
              '-'
            ) : (
              `${_removeTrailingZeros(
                parseFloat(price).toLocaleString('en-US', {
                  minimumFractionDigits: 20,
                }),
              )} USD`
            )}
          </S.StatusCol>
        )}
        {tab === 'open_orders' &&
          columnsSettings.activeColumns.includes('creation_time') && (
            <S.StatusCol style={{ textAlign: 'left' }}>
              {time_created ? (
                <>
                  <div>{timeCreatedDate}</div>
                  <span style={{ color: 'var(--object-secondary)' }}>
                    {timeCreatedTime}
                  </span>{' '}
                </>
              ) : (
                <div style={{ paddingLeft: '26px' }}>-</div>
              )}
            </S.StatusCol>
          )}
        {tab === 'history' &&
          columnsSettings.activeColumns.includes('execution_time') &&
          (executionTimeDate !== '-' ? (
            <S.StatusCol style={{ textAlign: 'left' }}>
              {executionTimeDate}
              <span style={{ color: 'var(--object-secondary)' }}>
                {executionTimeTime}
              </span>
            </S.StatusCol>
          ) : (
            <S.StatusCol style={{ textAlign: 'left' }}>-</S.StatusCol>
          ))}
        {tab === 'history' &&
          columnsSettings.activeColumns.includes('status') && (
            <S.ConfirmationsCol
              style={{
                fontWeight: 'bold',
                color: status === 'FILLED' ? 'var(--success)' : 'var(--error)',
              }}
            >
              {capitalize(status)}
            </S.ConfirmationsCol>
          )}
        {tab === 'history' &&
          columnsSettings.activeColumns.includes('total') && (
            <S.ConfirmationsCol style={{ textAlign: 'left' }}>
              {getCurrencyValue({
                // @ts-ignore
                currency: 'USD',
                value: new Decimal(
                  String(Number(size) * Number(price)),
                ).toFixed(),
                // @ts-ignore
                minprecision: getCurrencyPrecision('USD'),
              })}{' '}
              USD
            </S.ConfirmationsCol>
          )}
      </S.PositionIntro>
    </S.Row>
  );
};
