import { TabType } from 'features/Transactions/types';
import React, { Component } from 'react';
import {
  AccountType,
  AppSettingsType,
  CurrencyType,
  ExchangeAccountsType,
  UserInfoType,
} from '../types';

export type AppContextType = {
  ref: any;
  scrollToTop: any;
  accounts: AccountType[];
  appSettings: AppSettingsType;
  appToken: string | null;
  checkExpiredToken: (res: Response) => void;
  exchanges: ExchangeAccountsType[];
  getFiatWalletById: (accountId: number, walletId: number) => any;
  getAccountById: (id: number) => AccountType | null;
  getCurrencyPrecision: (currency: CurrencyType | undefined, location?: 'send-funds' | 'overview') => number;
  getCurrencyValue: any;
  getUserInfo: any;
  isConfirming: boolean;
  isEnteringOtp: boolean;
  isHideSidebar: boolean;
  rates: any;
  refetchAccounts: any;
  setIsConfirming: any;
  setIsEnteringOtp: any;
  setIsHideSidebar: any;
  multisigTab: any;
  setMultisigTab: any;
  confirmExecuteMultisigTransactionModal: any;
  setConfirmExecuteMultisigTransactionModal: any;
  multisigTransactionAsset: any;
  setMultisigTransactionAsset: any;
  setTransactionStatus: any;
  transactionStatus: any;
  user: UserInfoType | null;
  transactionsTab: TabType;
  setTransactionsTab: any;
  currentAccNode: any;
  setCurrentAccNode: any;

  modal: ModalProps | null;
  openModal: any;

  setStep: any;
  step: number;
  setTheme: any;
  theme: any;
  isAccountsLoading: boolean | undefined;

  setAsideMobileType: any;
  getAccountWallet: any;
  isAsideMobileType: boolean;
};

export interface ModalProps {
  title?: string;
  titleType?: 'success';
  titleRightText?: string;
  component: any;
  width?: string;
  onClose?: any;
  outsideClickClose?: boolean;
}

const AppContext = React.createContext<AppContextType>({
  ref: null,
  scrollToTop: () => {},
  accounts: [],
  appSettings: {
    user_single_transaction_limit_default: 500000,
    application_direct_link: '',
    application_playmarket_link: '',
    proposal_votes_value_to_approve: 3,
    proposal_votes_value_to_reject: 0,
    gnosis_node: '',
    gnosis_chain_id: '',
    gnosis_enabled: false,
    currencies_primary: [],
    currencies_secondary: [],
    currencies_icons: {},
    currencies_networks: {},
    google_auth_key: '',
    is_hotp_enabled: false,
    overview_decimals: {},
    sendfunds_decimals: {},
  },
  appToken: null,
  checkExpiredToken: () => {},
  exchanges: [],
  getAccountById: () => null,
  getCurrencyPrecision: () => 0,
  getCurrencyValue: () => {},
  getUserInfo: () => {},
  setStep: () => {},
  step: 1,
  isConfirming: false,
  isEnteringOtp: false,
  isHideSidebar: false,
  rates: null,
  refetchAccounts: () => {},
  setIsConfirming: false,
  setIsEnteringOtp: () => {},
  setIsHideSidebar: false,
  multisigTab: false,
  setMultisigTab: false,
  confirmExecuteMultisigTransactionModal: false,
  setConfirmExecuteMultisigTransactionModal: () => {},
  multisigTransactionAsset: false,
  setMultisigTransactionAsset: () => {},
  transactionStatus: {},
  setTransactionStatus: () => {},
  user: null,
  transactionsTab: 'transactions',
  setTransactionsTab: () => {},
  currentAccNode: null,
  setCurrentAccNode: () => {},
  getAccountWallet: () => {},
  getFiatWalletById: () => {},
  modal: null,
  openModal: () => {},
  theme: false,
  setTheme: () => {},
  isAccountsLoading: false,

  setAsideMobileType: () => {},
  isAsideMobileType: false,
});

export default AppContext;
