import styled from '@emotion/styled';
import { ReactComponent as SvgLogo } from '../../assets/icons/views/logo-multik.svg';

const Logo = styled(SvgLogo)`
  width: 128px;
  
`;

export default function Header() {
  return (
    <div style={{ color: 'var(--object-primary)', width: '100%' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          maxWidth: '80rem',
          margin: 'auto',
          padding: '1.5rem 2rem',
        }}
      >
        <div style={{ cursor: 'pointer', width: '128' }}>
          <Logo />
          {/* <img src={Logo} alt="logo" className="w-32" /> */}
        </div>
      </div>
    </div>
  );
}
