import axios, { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import { API_URL } from 'shared/constants/app';
import { AuthStoreInstance } from 'services';
import { requestPost } from './requests';
let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error: any, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

export const interceptors = () => {
  axios.interceptors.request.use((config: AxiosRequestConfig) => {
    if (typeof config.headers?.NOINTERCEPT !== 'undefined') {
      return config;
    }

    let headers: AxiosRequestHeaders | undefined = config.headers;

    const { url, method = '' } = config;
    config.baseURL = API_URL;
    config.url = `${url}`;

    if (typeof headers?.noToken === 'undefined') {
      const appToken = localStorage.getItem('appToken') ?? null;
      headers = {
        ...headers,
        ...(appToken && { authorization: `Bearer ${appToken}` }),
      };
    } else {
      delete headers.noToken;
    }

    if (['POST', 'PATCH'].includes(method)) {
      headers.contentType = 'application/json';
    }

    return {
      ...config,
      headers,
    };
  });

  axios.interceptors.response.use(
    (response) => response, // Directly return successful responses.
    async (error) => {
      const originalRequest = error.config;

      if (
        error.response.status === 401 &&
        !originalRequest._retry &&
        !error.response.data.error_message.includes('Incorrect password') &&
        !error.response.data.error_message.includes('User with email')
      ) {
        if (isRefreshing) {
          return new Promise(function (resolve, reject) {
            failedQueue.push({ resolve, reject });
          })
            .then(() => axios(originalRequest))
            .catch(Promise.reject);
        }

        originalRequest._retry = true;
        isRefreshing = true;

        const refreshToken = localStorage.getItem('refreshToken');
        try {
          const response = await fetch(`${API_URL}/auth/token/refresh/`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              refresh: refreshToken,
            }),
          });
          if (!response.ok) {
            throw new Error();
          }
          const data = await response.json();
          localStorage.setItem('appToken', data.jwt_token);
          processQueue(null, data.jwt_token);
          return axios(originalRequest);
        } catch (err) {
          localStorage.removeItem('appToken');
          localStorage.removeItem('refreshToken');
          AuthStoreInstance.signOut();
          window.location.href = '/login';
          processQueue(err, null);
          return Promise.reject(err);
        } finally {
          isRefreshing = false;
        }
      }

      return Promise.reject(error);
    },
  );
};
