import { useState, useRef, useEffect } from 'react';

interface UseObserveElementWidthProps {
  defaultWidth?: number;
}

export const useObserveElementWidth = <T extends HTMLElement>({
  defaultWidth = 0,
}: UseObserveElementWidthProps) => {
  const [width, setWidth] = useState(defaultWidth);
  const ref = useRef<T>(null);

  useEffect(() => {
    const observer = new ResizeObserver((entries: any) => {
      if (entries[0].contentRect.width === 0) {
        setWidth(defaultWidth);
      } else {
        setWidth(entries[0].contentRect.width);
      }
    });

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      ref.current && observer.unobserve(ref.current);
    };
  }, []);

  return {
    width,
    ref,
  };
};
