import { COINS_INFO, getCoinsInfoById } from 'shared/constants/coins';
import { getExchangeInfoById } from 'shared/constants/exchanges';
import {
  AccountType,
  AppSettingsType,
  CurrencyInfoType,
  CurrencyType,
  ExchangeAccountsType,
} from 'shared/types';

export const ORDERS_TABS: TabData[] = [
  {
    title: 'Open orders',
    tab_alias: 'open_orders',
    permission: 'transactions.view_transaction',
  },
  {
    title: 'History',
    tab_alias: 'history',
    permission: 'transactions.view_transaction',
  },
];

interface ViewOption {
  id: string;
  name: string;
  info: string;
}

const VIEW_OPTIONS: ViewOption[] = [
  {
    id: 'blockchain_transactions',
    name: 'Transactions',
    info: 'Transactions between exchanges, standalone wallets, etc.',
  },
  {
    id: 'offchain_transactions',
    name: 'Transfers',
    info: 'Internal transactions within one exchange account',
  },
];

export const getDropdownFilters = (
  exchanges: ExchangeAccountsType[],
  accounts: AccountType[],
  appSettings: AppSettingsType,
) => [
  {
    label: 'FROM:',
    entity: 'Exchange',
    field: 'exchange_from',
    entities: exchanges,
    fieldIdFn: (item: ExchangeAccountsType) => item.exchange,
    fieldNameFn: (item: ExchangeAccountsType) => item.exchange,
    fieldIconFn: (item: ExchangeAccountsType) =>
      getExchangeInfoById(item.exchange)?.logo_url,
  },
  {
    label: 'TO:',
    entity: 'Exchange',
    field: 'exchange_to',
    entities: exchanges,
    fieldIdFn: (item: ExchangeAccountsType) => item.exchange,
    fieldNameFn: (item: ExchangeAccountsType) => item.exchange,
    fieldIconFn: (item: ExchangeAccountsType) =>
      getExchangeInfoById(item.exchange)?.logo_url,
  },
  {
    label: 'FROM:',
    entity: 'Account',
    field: 'account_from',
    entities: accounts,
    fieldIdFn: (item: AccountType) => item.id,
    fieldNameFn: (item: AccountType) => item.name,
  },
  {
    label: 'TO:',
    entity: 'Account',
    field: 'account_to',
    entities: accounts,
    fieldIdFn: (item: AccountType) => item.id,
    fieldNameFn: (item: AccountType) => item.name,
  },
  {
    label: 'VIEW OPTIONS',
    entity: 'VIEW OPTIONS',
    field: 'is_internal_transfer',
    entities: VIEW_OPTIONS,
    fieldIdFn: (item: ViewOption) => item.id,
    fieldNameFn: (item: ViewOption) => item.name,
  },
  {
    label: 'CURRENCY',
    entity: 'Currency',
    field: 'currency',
    entities: [...appSettings.currencies_primary, ...appSettings.currencies_secondary].sort((a, b) => (a > b ? 1 : -1)),
    fieldIdFn: (item: CurrencyType) => item,
    fieldNameFn: (item: CurrencyType) => item,
    fieldIconFn: (item: CurrencyType) =>
      getCoinsInfoById(item, appSettings.currencies_icons)?.logo_url,
  },
];
