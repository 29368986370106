import Tippy from '@tippyjs/react';
import { ExchangeIconMin } from '../../components/ExchangeAbstract';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useTransfer } from 'features/Transfer/context/TransferContext';
import {
  AccountType,
  ColumnSide,
  CurrencyType,
  ExchangeApiIdType,
  WalletType,
  isCurrencyFiat,
} from 'shared/types';
import {
  getThreshold,
  isDepositAddressAvailable,
} from 'shared/helpers/transaction';
import AppContext from 'shared/contexts/AppContext';
import { QrModal } from 'features/Profile/view/components/QrModal';
import { AddressManagement } from 'shared/components';
import { getCoinsInfoById } from 'shared/constants/coins';
import { calculateCurrentThreshold } from 'features/Transfer/functions';

import * as S from './WalletStyled';
import { SettingsStoreInstance } from 'services';
import { _removeTrailingZeros } from 'shared/helpers/format';

interface WalletItemProps {
  side: ColumnSide;
  wallet: WalletType;
  account: AccountType;
  currency: CurrencyType;
}

export const WalletItem = ({
  side,
  wallet,
  account,
  currency,
}: WalletItemProps) => {
  const {
    isFromWallet,
    from,
    to,
    couldAddTransaction,
    couldAddTransactionProposal,
    changeFrom,
    changeTo,
    useGetWalletsTo,
    setTxAddress,
  } = useTransfer();
  const { appSettings } = SettingsStoreInstance;
  const {
    setCurrentAccNode,
    openModal,
    getCurrencyPrecision,
    getCurrencyValue,
  } = useContext(AppContext);
  const [domNode, setDomNode] = useState(null);
  const chosenWallet = side === 'from' ? from?.wallet : to?.wallet;
  const secondWallet = side === 'from' ? to?.wallet : from?.wallet;
  const onClick = side === 'from' ? changeFrom : changeTo;
  const isActive = wallet?.id === chosenWallet?.id;
  const balance = wallet?.available;
  const isZeroBalance = Number(balance) === 0;
  const coinInfo = getCoinsInfoById(currency, appSettings.currencies_icons);
  const [management, setManagement] = useState(false);

  const isDisabled =
    !(couldAddTransaction || couldAddTransactionProposal) ||
    (isCurrencyFiat(wallet.currency) &&
      !['KRAKEN'].includes(account.exchange)) ||
    (side === 'from' && isZeroBalance && !account.is_multisig) ||
    (side === 'to' && wallet?.id === secondWallet?.id) ||
    (side === 'to' && !secondWallet) ||
    (side === 'from' && account.exchange === 'WALLETS' && !account.is_multisig);

  const { refetch: fetchWalletsTo } = useGetWalletsTo(
    isActive ? null : currency,
  );

  const selectAccount = () => {
    if (!isDisabled) {
      onClick({
        exchangeId: account.exchange,
        accountId: account.id,
        walletId: wallet.id,
        currencyId: currency,
      });
      if (side === 'from') fetchWalletsTo();
      setTimeout(() => {
        // @ts-ignore
        domNode?.scrollIntoView({
          inline: 'center',
          block: 'center',
          behavior: 'smooth',
        });
      }, 0);
    }
  };

  const openQrModal = () => {
    openModal({
      title: 'Address management',
      component: () => (
        <AddressManagement
          walletTo={wallet}
          accountTo={account}
          walletFrom={wallet}
          accountFrom={account}
          side={side}
          management
          setTxAddress={setTxAddress}
        />
      ),
    });
  };

  const hasDepositAddress = isDepositAddressAvailable(
    account.exchange as ExchangeApiIdType,
    wallet.type as string,
    wallet.deposit_addresses,
  );

  const onRefChange = useCallback(
    (node: any) => {
      setDomNode(node);
      if (isFromWallet && wallet?.id === chosenWallet?.id && side === 'from') {
        setCurrentAccNode(node);
      }
    },
    [isFromWallet, wallet, chosenWallet, setCurrentAccNode],
  );

  const threshold = getThreshold(account, currency);

  const isViolated = useMemo(() => {
    if (!threshold) return false;
    return threshold.is_violated;
  }, [account]);

  const currentThreshold = threshold && calculateCurrentThreshold(threshold);

  const precision = getCurrencyPrecision(currency, 'send-funds');
  const totalValue = getCurrencyValue({
    currency: currency as CurrencyType,
    value: wallet?.total,
    minprecision: precision,
    location: 'send-funds',
  });
  const availableValue = getCurrencyValue({
    currency: currency as CurrencyType,
    value: wallet?.available,
    minprecision: precision,
    location: 'send-funds',
  });

  const totalIsLessThanPrecision =
    ['0.', ...Array(precision).fill('0')].join('') === totalValue;
  const availableIsLessThanPrecision =
    ['0.', ...Array(precision).fill('0')].join('') === availableValue;

  return (
    <S.WalletItemWrapper>
      <S.AccountRow
        isViolated={isViolated}
        ref={onRefChange}
        disabled={isDisabled}
        className={isActive ? 'active' : ''}
        onClick={selectAccount}
      >
        <ExchangeIconMin
          disabled={false}
          style={{
            backgroundImage: `url(${
              coinInfo?.logo_url ||
              (isActive
                ? '/i/coins/unknown-active.svg'
                : '/i/coins/unknown.svg')
            })`,
          }}
        />
        <S.CurrencyIso isViolated={isViolated} selected={isActive}>
          {currency}
          <Tippy
            theme="transparent"
            content={
              <>
                Total value of ({threshold?.current_amount} {currency}) in your
                account exceeds the threshold ({currentThreshold} {currency})
              </>
            }
          >
            <span>{isViolated && <S.LimitIcon />}</span>
          </Tippy>
        </S.CurrencyIso>
        {wallet.total === null && wallet.available === null ? (
          <S.Currency selected={isActive}>
            <Tippy theme="transparent" content={'Balance is not supported'}>
              <span>
                <S.AccWalletTotal>-</S.AccWalletTotal>
                <br />
                <S.AccWalletAvailable>-</S.AccWalletAvailable>
              </span>
            </Tippy>
          </S.Currency>
        ) : (
          <S.Currency selected={isActive}>
            <Tippy
              theme="transparent"
              content={
                <>
                  <S.HintText>
                    Total
                    {(totalIsLessThanPrecision ||
                      availableIsLessThanPrecision) &&
                      `: ${_removeTrailingZeros(String(wallet?.total))} ${currency}`}
                  </S.HintText>
                  <S.HintText>
                    Available
                    {(totalIsLessThanPrecision ||
                      availableIsLessThanPrecision) &&
                      `: ${_removeTrailingZeros(String(wallet?.available))} ${currency}`}
                  </S.HintText>
                </>
              }
            >
              <span>
                <S.AccWalletTotal>
                  {Number(wallet?.total) > 0
                    ? totalIsLessThanPrecision
                      ? '<0.00'
                      : totalValue
                    : 0}
                </S.AccWalletTotal>
                <br />
                <S.AccWalletAvailable>
                  {Number(wallet?.available) > 0
                    ? availableIsLessThanPrecision
                      ? '<0.00'
                      : availableValue
                    : 0}
                </S.AccWalletAvailable>
              </span>
            </Tippy>
          </S.Currency>
        )}
      </S.AccountRow>
      {hasDepositAddress && (
        <S.IconQr isActive={isActive} onClick={openQrModal} />
      )}
    </S.WalletItemWrapper>
  );
};
