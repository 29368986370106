import { useEffect, useState } from 'react';

export const useOptionalColumns = (key: string, columns: any[]) => {
  const [activeColumns, setActiveColumns] = useState<any[]>([]);
  useEffect(() => {
    const jsonStoredColumns = JSON.parse(
      localStorage.getItem(`${key}_columns`) || '[]',
    );

    if (
      localStorage.getItem(`${key}_columns`) === null &&
      jsonStoredColumns.length === 0
    ) {
      localStorage.setItem(`${key}_columns`, JSON.stringify(columns));
    }

    const storedColumns = JSON.parse(
      localStorage.getItem(`${key}_columns`) || '[]',
    );
    setActiveColumns(storedColumns);
  }, [key]);

  const onChange = (column: any) => {
    const columnsArray = activeColumns ? activeColumns : [];
    if (columnsArray.includes(column.target.value)) {
      const filtered = columnsArray.filter(
        (col: string) => col !== column.target.value,
      );
      setActiveColumns(filtered);
      localStorage.setItem(`${key}_columns`, JSON.stringify(filtered));
      return;
    } else {
      setActiveColumns((currentCols: any[]) => {
        localStorage.setItem(
          `${key}_columns`,
          JSON.stringify([...currentCols, column.target.value]),
        );
        return [...currentCols, column.target.value];
      });
      return;
    }
  };

  return { columns, onChange, activeColumns };
};
