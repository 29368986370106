import { observer } from 'mobx-react-lite';
import { UserStoreInstance } from 'services';
import { UsersTable } from './components/UsersTable';

import * as S from './styled';
import { ProtectedArea } from 'shared/components';

export const Settings = observer(() => {
  const { user } = UserStoreInstance;

  if (!user) return null;
  const { email, has_hotp_secret } = user;

  return (
    <ProtectedArea displayMessage every={['users.change_user']}>
      <S.ProfileWrapper
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 0.5 } }}
        exit={{ opacity: 0, transition: { duration: 0.5 } }}
      >
        <S.HeaderInfo>
          The table contains information about users, including their voice and
          limit settings. Adding and removing users, as well as resetting OTP
          codes, is done through the Mobile Provisioning App. For more details,
          please refer to the{' '}
          <a
            href="https://exantech.atlassian.net/wiki/spaces/CH/pages/298516485/User+limits"
            target="_blank"
            style={{
              textDecoration: 'underline',
              cursor: 'pointer',
              textUnderlineOffset: '3px',
            }}
          >
            instruction manual
          </a>
          .
        </S.HeaderInfo>
        <UsersTable title="Users" />
      </S.ProfileWrapper>
    </ProtectedArea>
  );
});
