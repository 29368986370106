import { FC, useContext, useEffect, useRef, useState } from 'react';
import AppContext from 'shared/contexts/AppContext';

import * as S from '../Modal/styled';
import { useChangeProposalStatus } from 'features/Transactions/view/components/hooks/useChangeProposalStatus';
import {
  CodeInput,
  digitsNumber,
  OtpProposalDetails,
  SubmitButton,
} from './shared';
import { StatusProposalChange } from 'shared/types';
import { UserStoreInstance } from 'services';
import { UnsetOtp } from './UnsetOtp';
import { parseError } from 'shared/helpers/errors';
import { canExecuteTransaction } from 'shared/helpers/transaction';
import { ThresholdViolationInfo } from 'features/Transactions/view/components/shared/ThresholdViolationInfo';
import { useTransfer } from 'features/Transfer/context/TransferContext';

export const tfaRequired = ['DERIBIT', 'FTX'];

interface UpdateOtpModalProps {
  proposal: any;
  status: StatusProposalChange;
  setIsUpdating: any;
  setIsOpen: any;
  setHighlighted: any;
  setError: any;
  setIsBeingVoted?: any;
  hideTfa?: boolean;
  responsive?: boolean;
}

export const UpdateOtpModal: FC<UpdateOtpModalProps> = ({
  proposal,
  status,
  setIsUpdating,
  setIsOpen,
  setHighlighted,
  setError,
  setIsBeingVoted,
  responsive = false,
  hideTfa = false,
}) => {
  const [otpErrorText, setOtpErrorText] = useState<string | null>(null);
  const [tfaErrorText, setTfaErrorText] = useState<string | null>(null);
  const otpRef = useRef(null);
  const tfaRef = useRef(null);
  const { openModal, appSettings } = useContext(AppContext);
  const { network } = useTransfer();
  const { account_from, account_to, amount, currency, wallet_from, wallet_to, is_fiat, network_info } =
    proposal;
  const { user } = UserStoreInstance;

  const onClose = () => {
    openModal(null);
  };

  const [otp, setOtp] = useState('');
  const [tfa, setTfa] = useState('');
  const timerRef = useRef<number>(-1);

  const { mutate, error, isLoading } = useChangeProposalStatus({
    onClose,
    proposal,
    setIsUpdating,
    setHighlighted,
    setIsOpen,
  });

  const onOtpReset = () => {
    setOtp('');
  };

  const onTfaReset = () => {
    setTfa('');
  };

  useEffect(() => {
    const errorText = parseError(error, appSettings, {
      fromWallet: wallet_from,
      toWallet: { ...wallet_to, currency },
      fromAccount: account_from,
      user,
      currency,
      network,
    });
    if (errorText?.toUpperCase()?.includes('TFA')) {
      setTfaErrorText(errorText);
      onTfaReset();
    } else if (errorText?.toUpperCase()?.includes('OTP')) {
      setOtpErrorText(errorText);
      onOtpReset();
    } else if (errorText) {
      setError(errorText);
      openModal(null);
    }
  }, [error]);

  const isOtpRequired = appSettings.is_hotp_enabled && status !== 'execute';
  const isTfaRequired =
    !hideTfa &&
    ((tfaRequired.includes(account_from?.exchange) && status !== 'vote-down') ||
      status === 'execute');
  const aboutToExecute = canExecuteTransaction(
    user?.vote_weight,
    proposal.sum_of_votes,
    appSettings.proposal_votes_value_to_approve,
  );
  const isOtpNotEntered = isOtpRequired && otp.length < digitsNumber;
  const isTfaNotEntered =
    isTfaRequired && aboutToExecute && tfa.length < digitsNumber;
  const disabled = (isLoading || isOtpNotEntered) && !error;

  useEffect(() => {
    window.clearTimeout(timerRef.current);
    timerRef.current = window.setTimeout(() => {
      if (
        (otp.length >= digitsNumber || !isOtpRequired) &&
        (tfa.length >= digitsNumber || !(isTfaRequired && aboutToExecute))
      ) {
        onSubmit();
      }
    }, 900);
  }, [otp, tfa]);

  if (isOtpRequired && !user?.has_hotp_secret) {
    return <UnsetOtp />;
  }

  const onSubmit = () => {
    if (setIsBeingVoted) {
      setIsBeingVoted(true);
    }
    mutate(
      { status, tfa, otp },
      {
        onSettled: () => {
          if (setIsBeingVoted) {
            setIsBeingVoted(false);
          }
        },
      },
    );
  };

  const onOtpChange = (value: string) => {
    setOtpErrorText(null);
    setOtp(value);
  };

  const onTfaChange = (value: string) => {
    setTfaErrorText(null);
    setTfa(value);
  };

  return (
    <>
      <OtpProposalDetails
        amount={amount}
        currency={currency}
        fromAccount={account_from}
        walletFromType={wallet_from?.type}
        walletToType={wallet_to?.type}
        toAccount={account_to}
        isFiat={is_fiat}
        networkInfo={network_info}
      />
      {aboutToExecute && (
        <S.ThresholdViolationInfoWrapper
          style={{ marginTop: status === 'execute' ? 0 : 20 }}
        >
          <ThresholdViolationInfo tx={proposal} />
        </S.ThresholdViolationInfoWrapper>
      )}
      <S.ModalDiv />
      <CodeInput
        responsive={responsive}
        ref={otpRef}
        value={otp}
        autoFocus={true}
        required={isOtpRequired}
        title="Multik Authenticator code"
        onChange={onOtpChange}
        onReset={onOtpReset}
        errorText={otpErrorText}
        noMargin={!(isTfaRequired && aboutToExecute)}
      />
      {otpErrorText && (
        <S.ModalError
          responsive={responsive}
          noMargin={!(isTfaRequired && aboutToExecute)}
          data-id="otp-error"
        >
          {otpErrorText}
        </S.ModalError>
      )}
      <CodeInput
        responsive={responsive}
        ref={tfaRef}
        value={tfa}
        autoFocus={!isOtpRequired}
        required={isTfaRequired && aboutToExecute}
        title={`${account_from?.exchange} Authenticator code`}
        onChange={onTfaChange}
        onReset={onTfaReset}
        errorText={tfaErrorText}
        noMargin
      />
      {tfaErrorText && (
        <S.ModalError responsive={responsive} noMargin data-id="tfa-error">
          {tfaErrorText}
        </S.ModalError>
      )}
      <S.ModalDiv />
      <S.ConfirmButton type="button" disabled={disabled} onClick={onSubmit}>
        <SubmitButton
          isSubmit={isLoading}
          errorText={otpErrorText || tfaErrorText}
        />
      </S.ConfirmButton>
    </>
  );
};
