import { CurrencyType } from 'shared/types';
import Decimal from 'decimal.js-light';
import Tippy from '@tippyjs/react';

import * as S from '../../styled';
import { ViewType } from 'features/Overview/types';
import { AccountsStoreInstance } from 'services';
import { IconExternal } from 'features/Transfer/view/newComponents/Account/AccountStyled';
import { MAX_INTEGER } from 'ethereumjs-util';
import { _removeTrailingZeros } from 'shared/helpers/format';

export const TableCoinCell = ({
  availableCurrency,
  data,
  cell,
  chosenRate,
  exchangeName,
  getCurrencyPrecision,
  getCurrencyValue,
  isCryptoTab,
  rates,
  setCell,
  view,
  viewInfo,
  walletId,
}: {
  availableCurrency: CurrencyType | 'TOTAL';
  data: any;
  cell: string;
  chosenRate: any;
  exchangeName: string;
  getCurrencyPrecision: any;
  getCurrencyValue: any;
  isCryptoTab: boolean;
  rates: any;
  setCell: any;
  view: string;
  walletId?: any;
  viewInfo: {
    id: string;
    label: ViewType;
    currency: CurrencyType;
    currency_label: string;
    divisor: number;
    divisor_label: any;
  };
}) => {
  const { walletsMap, unsupportedBalances, getAccountById } =
    AccountsStoreInstance;
  const wallet = walletsMap.get(data.walletId);
  const isTotal = availableCurrency === 'TOTAL';

  const cellID = exchangeName + '-' + availableCurrency;

  const coinBalance = data?.values[availableCurrency] || null;

  const calculatedBalance = coinBalance ? { ...coinBalance } : null;
  const hasUnsupportedBalance =
    unsupportedBalances[exchangeName]?.[availableCurrency];

  const account = getAccountById(wallet?.account);

  if (
    wallet?.available === null &&
    wallet?.total === null &&
    account?.explorer_link
  ) {
    return (
      <S.DataCell key={cellID} active={false} total={false}>
        <Tippy
          placement="top"
          theme="transparent"
          content={'View in blockchain explorer'}
        >
          <a target="_blank" href={account?.explorer_link}>
            <IconExternal style={{ marginRight: -4 }} />
          </a>
        </Tippy>
      </S.DataCell>
    );
  }

  if (
    !isTotal &&
    coinBalance &&
    !isCryptoTab &&
    rates[chosenRate][availableCurrency]
  ) {
    calculatedBalance.available = new Decimal(coinBalance.available)
      .mul(rates[chosenRate][availableCurrency])
      .div(viewInfo.divisor)
      .toFixed();
    calculatedBalance.total = new Decimal(coinBalance.total)
      .mul(rates[chosenRate][availableCurrency])
      .div(viewInfo.divisor)
      .toFixed();
  }

  const isRateAvailable =
    !isTotal &&
    Boolean(
      rates[chosenRate] && rates[chosenRate][availableCurrency]
        ? rates[chosenRate][availableCurrency]
        : false,
    );

  const isRateError =
    !isTotal && !isRateAvailable && calculatedBalance?.total > 0;

  const valueCurrency =
    viewInfo.id === 'usd_k' || viewInfo.id === 'usd_m'
      ? viewInfo.id
      : isTotal || !isCryptoTab
      ? chosenRate
      : availableCurrency;

  const precision = getCurrencyPrecision(valueCurrency as CurrencyType);
  const totalValue = getCurrencyValue({
    currency: valueCurrency as CurrencyType,
    value: String(
      isRateError && viewInfo.id !== 'crypto'
        ? parseInt(calculatedBalance?.total)
        : calculatedBalance?.total,
    ),
    minprecision: isRateError ? undefined : precision,
  });
  const availableValue = getCurrencyValue({
    currency: valueCurrency as CurrencyType,
    value: String(
      isRateError && viewInfo.id !== 'crypto'
        ? parseInt(calculatedBalance?.available)
        : calculatedBalance?.available,
    ),
    minprecision: isRateError ? undefined : precision,
  });

  const totalIsLessThanPrecision =
    viewInfo.id === 'crypto' &&
    ['0.', ...Array(precision).fill('0')].join('') === totalValue;
  const availableIsLessThanPrecision =
    viewInfo.id === 'crypto' &&
    ['0.', ...Array(precision).fill('0')].join('') === availableValue;

  return calculatedBalance && !hasUnsupportedBalance ? (
    <S.DataCell
      key={cellID}
      onClick={() => setCell(cellID)}
      active={cell === cellID}
      total={availableCurrency === 'TOTAL'}
    >
      <Tippy
        theme="transparent"
        placement="right"
        content={
          isCryptoTab ? (
            <>
              {isRateError ? (
                <S.ErrorHintText>Rate is not available</S.ErrorHintText>
              ) : (
                <>
                  <S.HintText>
                    Total
                    {(totalIsLessThanPrecision ||
                      availableIsLessThanPrecision) &&
                      `: ${_removeTrailingZeros(String(calculatedBalance?.total))} ${valueCurrency}`}
                  </S.HintText>
                  <S.HintText>
                    Available
                    {(totalIsLessThanPrecision ||
                      availableIsLessThanPrecision) &&
                      `: ${_removeTrailingZeros(String(calculatedBalance?.available))} ${valueCurrency}`}
                  </S.HintText>
                </>
              )}
            </>
          ) : (
            <>
              {isRateError ? (
                <S.ErrorHintText>Rate is not available</S.ErrorHintText>
              ) : (
                <>
                  <S.HintText>
                    Total:{' '}
                    {coinBalance?.total > 0
                      ? getCurrencyValue({
                          currency: (isTotal
                            ? viewInfo.id
                            : availableCurrency) as CurrencyType,
                          value: String(coinBalance?.total),
                        })
                      : 0}
                    {` ${isTotal ? view : availableCurrency}`}
                  </S.HintText>
                  <S.HintText>
                    Available:{' '}
                    {coinBalance?.available > 0
                      ? getCurrencyValue({
                          currency: (isTotal
                            ? viewInfo.id
                            : availableCurrency) as CurrencyType,
                          value: String(coinBalance?.available),
                        })
                      : 0}
                    {` ${isTotal ? view : availableCurrency}`}
                  </S.HintText>
                </>
              )}
            </>
          )
        }
      >
        <span>
          <S.Total bold={isTotal} error={isRateError}>
            {(viewInfo.id === 'crypto' && availableCurrency !== 'TOTAL') ||
            isRateError ? null : (
              <S.CurrencyLabelWrap>
                {viewInfo.currency_label}
              </S.CurrencyLabelWrap>
            )}
            {calculatedBalance?.total > 0
              ? totalIsLessThanPrecision
                ? '<0.00'
                : totalValue
              : 0}
            {viewInfo.id === 'crypto' || isRateError
              ? null
              : viewInfo?.divisor_label}
          </S.Total>
          <S.Available bold={availableCurrency === 'TOTAL'} error={isRateError}>
            {(viewInfo.id === 'crypto' && availableCurrency !== 'TOTAL') ||
            isRateError ? null : (
              <S.CurrencyLabelWrap>
                {viewInfo.currency_label}
              </S.CurrencyLabelWrap>
            )}
            {calculatedBalance?.available > 0
              ? availableIsLessThanPrecision
                ? '<0.00'
                : availableValue
              : 0}
            {viewInfo.id === 'crypto' || isRateError
              ? null
              : viewInfo?.divisor_label}
          </S.Available>
        </span>
      </Tippy>
    </S.DataCell>
  ) : (
    <S.DataCell
      key={cellID}
      active={false}
      total={availableCurrency === 'TOTAL'}
    >
      <S.Total bold={false}>-</S.Total>
    </S.DataCell>
  );
};
