import { useFilters } from 'shared/hooks/useFilters';
import {
  Input,
  Label,
  LabelWrap,
  FilterContent,
  Checkbox,
  ExchangeIcon,
  FilterClearButton,
} from 'shared/components/FilterAbstract/FilterAbstract';
import { useToggle } from 'shared/hooks/useToggle';
import { useState, useMemo, useCallback } from 'react';
import { formatAccount } from 'shared/helpers/format';

import * as S from './styled';
import _ from 'lodash';

interface FilterDropdownProps extends FiltersProps {
  entities: any[];
  label: string;
  field: string;
  entity: string;
  fieldIdFn: FieldGetter;
  fieldNameFn: FieldGetter;
  fieldIconFn?: FieldGetter;
  forceOpen?: boolean;
}

interface LabelTextProps {
  label: string;
  entity: string;
}

const LabelText = ({ label, entity }: LabelTextProps) => (
  <Label>
    {label}
    {label !== entity && <span>&nbsp;&nbsp;{entity}</span>}
  </Label>
);

type FieldGetter = (item: any) => any;

interface ListItemProps {
  entity: any;
  field: string;
  onChange: any;
  checked: boolean;
  fieldIdFn: FieldGetter;
  fieldNameFn: FieldGetter;
  fieldIconFn?: FieldGetter;
}

const ListItem = ({
  entity,
  field,
  onChange,
  checked,
  fieldIdFn,
  fieldNameFn,
  fieldIconFn,
}: ListItemProps) => {
  const id = useMemo(() => fieldIdFn(entity), [entity, fieldIdFn]);
  const name = useMemo(() => fieldNameFn(entity), [entity, fieldNameFn]);
  const account = useMemo(
    () => formatAccount(fieldNameFn(entity)),
    [entity, fieldNameFn],
  );
  const icon = useMemo(
    () => (fieldIconFn ? fieldIconFn(entity) : null),
    [entity, fieldIconFn],
  );

  return (
    <S.CheckboxLabel key={id}>
      <Checkbox
        checked={checked}
        onChange={onChange}
        type="checkbox"
        name={field}
        title={name}
        value={id}
      />

      <span>
        {icon && (
          <ExchangeIcon
            style={{
              backgroundImage: `url(${icon})`,
            }}
          />
        )}
        {account}
      </span>
    </S.CheckboxLabel>
  );
};

export const parseValues = (
  statuses: any[] = [],
  action: boolean,
  value: any,
) => {
  const clone = _.cloneDeep(statuses);

  if (action) {
    clone.push(value);
  } else {
    const index = clone.findIndex((status) => status === value);
    if (index !== -1) {
      clone.splice(index, 1);
    }
  }

  return _.uniq(clone);
};

export const FilterDropdown = ({
  entities,
  label,
  field,
  entity,
  fieldIdFn,
  fieldNameFn,
  fieldIconFn,
  storageKey,
  onSubmit,
  forceOpen,
}: FilterDropdownProps) => {
  const [isOpen, toggleList] = useToggle(forceOpen);
  const [query, setQuery] = useState('');
  const [getFilters, setFilters] = useFilters(storageKey);
  const [values, setValues] = useState(getFilters()[field]);

  const onReset = () => {
    setFilters({
      [field]: [],
    });
    setValues([]);
    setQuery('');
    toggleList(false);
    onSubmit();
  };

  const onChangeQuery = useCallback((e: any) => {
    setQuery(e.target.value);
  }, []);

  const onOpenList = useCallback(() => {
    toggleList(true);
  }, []);

  const onChange = useCallback(
    (e: any) => {
      const newValues = parseValues(values, e.target.checked, e.target.value);
      setFilters({
        [field]: newValues,
      });
      setValues(newValues);
      // TODO: implement onChange
      onSubmit();
    },
    [values, field],
  );

  const chosenItems = useMemo(
    () =>
      entities?.filter((entity) => values?.includes(String(fieldIdFn(entity)))),
    [values, entities],
  );

  const unchosenItems = useMemo(
    () =>
      entities?.filter(
        (entity) =>
          fieldNameFn(entity)?.toLowerCase()?.includes(query?.toLowerCase()) &&
          !values?.includes(String(fieldIdFn(entity))),
      ),
    [values, entities, query],
  );

  return (
    <>
      <LabelWrap>
        <LabelText label={label?.toLowerCase()} entity={entity?.toLowerCase()} />
        {values?.length > 0 && (
          <FilterClearButton type="button" onClick={onReset}>
            Clear
          </FilterClearButton>
        )}
      </LabelWrap>

      <S.FilterWrap opened={isOpen}>
        <Input
          type="text"
          name="filter"
          value={query}
          onChange={onChangeQuery}
          className="filter_input"
          placeholder={`Search ${entity?.toLowerCase()}`}
          onFocus={onOpenList}
        />
        <S.FilterDropdownToggle
          type="button"
          className="dropdown"
          onClick={() => toggleList()}
        >
          <S.FilterDropdownIcon />
        </S.FilterDropdownToggle>

        <FilterContent className="filter_content">
          {chosenItems?.length > 0 && (
            <S.ChoosenItem>
              {chosenItems.map((entity: any) => (
                <ListItem
                  key={fieldIdFn(entity)}
                  onChange={onChange}
                  checked={true}
                  entity={entity}
                  field={field}
                  fieldIconFn={fieldIconFn}
                  fieldIdFn={fieldIdFn}
                  fieldNameFn={fieldNameFn}
                />
              ))}
              {isOpen && entities?.length !== values?.length && <S.FilterHr />}
            </S.ChoosenItem>
          )}

          <div className="form">
            <div>
              {unchosenItems.map((entity: any) => (
                <S.ExchangeItem key={fieldIdFn(entity)}>
                  <ListItem
                    checked={false}
                    onChange={onChange}
                    entity={entity}
                    field={field}
                    fieldIconFn={fieldIconFn}
                    fieldIdFn={fieldIdFn}
                    fieldNameFn={fieldNameFn}
                  />
                </S.ExchangeItem>
              ))}
            </div>
          </div>
        </FilterContent>
      </S.FilterWrap>
    </>
  );
};
