import * as S from 'features/Orders/view/styled';
import { useContext } from 'react';
import AppContext from 'shared/contexts/AppContext';
import { FilterSearch } from '../Filters';

import { useFilters } from 'shared/hooks/useFilters';
import { useIsMobile } from 'shared/hooks/useIsMobile';
import queryString from 'query-string';
import { FilterDropdown } from '../Filters/FilterDropdown';
import { FilterSide } from '../Filters/FilterSide';
import { FilterDates } from '../Filters/FilterDates';
import { FilterStatus } from '../Filters/FilterStatus';

interface OrdersFiltersProps {
  onSubmit: (query: string) => void;
  disabled: boolean;
  isMobile: boolean;
  isAsideButtonChecked: string;
  setIsAsideButtonChecked: any;
  setIsMobile: any;
  search: string;
  setSearch: any;
  tab: string;
}

export const stringifyUrl = (url: string, filters: any) => {
  return queryString.stringifyUrl(
    {
      url,
      query: {
        ...filters,
      },
    },
    { arrayFormat: 'separator' },
  );
};

export const OrdersFilters = ({
  onSubmit,
  disabled,
  search,
  tab,
  setSearch,
}: OrdersFiltersProps) => {
  const storageKey = `orders_filters_${tab}`;
  const { accounts, exchanges, appSettings } = useContext(AppContext);

  const [getFilters] = useFilters(`orders_filters_${tab}`);
  const filters = getFilters();
  const [isMobile, isAsideButtonChecked, handleAsideSize] = useIsMobile();

  function countElements(obj: any) {
    let count = 0;

    for (const key in obj) {
      if (Array.isArray(obj[key])) {
        count += obj[key].length;
      } else {
        count++;
      }
    }

    return count;
  }

  const filtersCount = countElements(filters);

  return (
    <S.Filters isMobile={isMobile} isAsideButtonChecked={isAsideButtonChecked}>
      {isMobile && (
        <S.FiltersData onClick={handleAsideSize} isMobile={isMobile}>
          <S.FiltersCounter>{filtersCount}</S.FiltersCounter>
          <S.FiltersIcon />
        </S.FiltersData>
      )}
      {/* <S.Filter isMobile={isMobile}>
        <FilterSearch
          storageKey={storageKey}
          // @ts-ignore
          onSubmit={onSubmit}
          search={search}
          setSearch={setSearch}
          disabled={disabled}
        />
      </S.Filter>{' '} */}

      {tab === 'history' && (
        <S.Filter isMobile={isMobile}>
          <FilterDates
            storageKey={storageKey}
            // @ts-ignore
            onSubmit={onSubmit}
            disabled={disabled}
          />
        </S.Filter>
      )}
      <S.Filter isMobile={isMobile} key={`${storageKey}_type`}>
        <FilterDropdown
          // @ts-ignore
          onSubmit={onSubmit}
          forceOpen={true}
          disabled={disabled}
          storageKey={storageKey}
          label="Order Type"
          entity="Order Type"
          field="type"
          entities={[
            {
              id: 'LIMIT',
              name: 'Limit',
            },
            {
              id: 'MARKET',
              name: 'Market',
            },
            {
              id: 'STOP_LIMIT',
              name: 'Stop Limit',
            },
            {
              id: 'STOP_MARKET',
              name: 'Stop Market',
            },
            {
              id: 'TAKE_PROFIT',
              name: 'Take Profit',
            },
            {
              id: 'TRAILING_STOP',
              name: 'Trailing Stop',
            },

            {
              id: 'TAKE_PROFIT_MARKET',
              name: 'Take Profit Market',
            },
          ]}
          fieldIdFn={(item: any) => item.id}
          fieldNameFn={(item: any) => item.name}
        />
      </S.Filter>
      <S.Filter isMobile={isMobile} key={`${storageKey}_side`}>
        <FilterSide
          // @ts-ignore
          onSubmit={onSubmit}
          disabled={disabled}
          storageKey={storageKey}
          label="SIDE"
          entity="SIDE"
          field="side"
          entities={[
            {
              id: 'BUY',
              name: 'Buy',
            },
            {
              id: 'SELL',
              name: 'Sell',
            },
          ]}
          fieldIdFn={(item: any) => item.id}
          fieldNameFn={(item: any) => item.name}
        />
      </S.Filter>
      {tab === 'history' && (
        <S.Filter isMobile={isMobile}>
          <FilterStatus
            statuses={['FILLED', 'CANCELED']}
            storageKey={storageKey}
            // @ts-ignore
            onSubmit={onSubmit}
            disabled={disabled}
          />
        </S.Filter>
      )}
      <S.AsideButton
        onClick={handleAsideSize}
        isMobile={isMobile}
        isAsideButtonChecked={isAsideButtonChecked}
      >
        <S.ArrowIconAside />
      </S.AsideButton>
    </S.Filters>
  );
};
