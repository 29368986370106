import { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import Decimal from 'decimal.js-light';
import Tippy from '@tippyjs/react';
import useOnClickOutside from 'use-onclickoutside';

import {
  SettingsStoreInstance,
  CurrenciesStoreInstance,
  AccountsStoreInstance,
} from 'services';

import { CurrencyType } from 'shared/types';
import { getCurrencyLogo } from 'shared/helpers/currencies';
import { useLocalStorage } from 'shared/hooks/useLocalStorage';

import { CurrencySelector } from './components';
import { availableViews } from '../constants';

import { ViewType } from '../types';
import * as S from './styled';
import { OverviewStoreInstance } from '../mobx/OverviewStore';
import { Loader, Placeholder } from 'shared/components';
import { OverviewRows } from './components/OverviewRows';
import { getCoinsInfoById } from 'shared/constants/coins';
import { _removeTrailingZeros } from 'shared/helpers/format';

const overviewFilters = 'overview_filters';

const Overview = () => {
  const { appSettings } = SettingsStoreInstance;
  const { view, setView, balances, viewInfo, chosenRate } =
    OverviewStoreInstance;
  const { exchanges, accounts, isAccountsLoading } = AccountsStoreInstance;
  const { rates, getCurrencyPrecision, getCurrencyValue } =
    CurrenciesStoreInstance;

  const [chosenCurrency, setChosenCurrency] = useLocalStorage(overviewFilters, [
    ...appSettings.currencies_primary,
  ]);

  useEffect(() => {
    const filteredLocalStorage = chosenCurrency.filter((item: CurrencyType) =>
      availableCurrencies.includes(item),
    );

    if (filteredLocalStorage.length !== chosenCurrency.length) {
      setChosenCurrency(filteredLocalStorage);
    }
  }, []);

  const [cell, setCell] = useState<string>('-');
  const [newCell, setNewCell] = useState<any>(null);

  const dropdownRef = useRef(null);
  const currencyRef = useRef<HTMLDivElement>(null);
  const tableWrap = useRef<HTMLDivElement>(null);

  const initialCurrenciesState: CurrencyType[] = [
    ...appSettings.currencies_primary,
  ];

  useOnClickOutside(dropdownRef, () => setView());

  const availableCurrencies: CurrencyType[] = [
    ...appSettings.currencies_primary,
    ...appSettings.currencies_secondary,
  ];

  const isShorten = ['usd_k', 'usd_m'].includes(viewInfo.id);

  const isCryptoTab = view === 'CRYPTO';
  const balancesByCoin: {
    [key: string]: { available: string; total: string };
  } = {};

  // calculate total and available balances for each coin
  Object.keys(balances).forEach((exchangeName) => {
    const exchangeValues = balances[exchangeName].values;

    availableCurrencies.forEach((currency) => {
      if (!exchangeValues[currency]) {
        return;
      }

      const coinBalance = balancesByCoin[currency] || {
        available: 0,
        total: 0,
      };

      coinBalance.available = new Decimal(exchangeValues[currency].available)
        .mul(isCryptoTab ? 1 : rates[chosenRate][currency] || 0)
        .div(viewInfo.divisor)
        .plus(coinBalance.available)
        .toFixed();
      coinBalance.total = new Decimal(exchangeValues[currency].total)
        .mul(isCryptoTab ? 1 : rates[chosenRate][currency] || 0)
        .div(viewInfo.divisor)
        .plus(coinBalance.total)
        .toFixed();

      balancesByCoin[currency] = coinBalance;
    });
  });

  function handleChangeCurrency(currency: CurrencyType) {
    if (chosenCurrency.includes(currency)) {
      const newChosenCurrency = chosenCurrency.filter(function (
        item: CurrencyType,
      ) {
        return item !== currency;
      });
      setChosenCurrency(newChosenCurrency);

      if (navigator.userAgent.match(/firefox|fxios/i)) {
        tableWrap?.current?.scrollTo(0, 0);
      }
    } else {
      setChosenCurrency([...chosenCurrency, currency]);
      setNewCell(null);
      const tableRef = tableWrap.current;
      setTimeout(() => {
        if (tableRef) {
          currencyRef?.current?.scrollIntoView({
            inline: 'center',
            behavior: 'smooth',
          });
          setNewCell(currency);
        }
      }, 10);
    }
  }

  function handleResetCurrency() {
    if (navigator.userAgent.match(/firefox|fxios/i)) {
      tableWrap?.current?.scrollTo(0, 0);
    }
    setChosenCurrency(initialCurrenciesState);
  }

  const allTotalAvailable = Object.keys(balances).reduce(
    // @ts-ignore
    (sum, key) => sum + parseFloat(balances[key].values.TOTAL.available),
    0,
  );

  const allTotalValue = Object.keys(balances).reduce(
    // @ts-ignore
    (sum, key) => sum + parseFloat(balances[key].values.TOTAL.total),
    0,
  );

  const initialInChoosen = initialCurrenciesState.filter((currency) =>
    chosenCurrency.includes(currency),
  );

  const filteredChoosenCurrency = [
    ...initialInChoosen,
    ...chosenCurrency.filter(
      (currency: CurrencyType) => !initialInChoosen.includes(currency),
    ),
  ];

  return (
    <S.Wrap
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      exit={{ opacity: 0, transition: { duration: 0.5 } }}
    >
      <S.CoinFilter>
        <CurrencySelector
          availableCurrencies={availableCurrencies}
          chosenCurrency={chosenCurrency}
          handleChangeCurrency={handleChangeCurrency}
          initialCurrenciesState={initialCurrenciesState}
          handleResetCurrency={handleResetCurrency}
        />
      </S.CoinFilter>
      {!isAccountsLoading && accounts.length && exchanges.length ? (
        <S.TableShadow>
          <S.TableWrap ref={tableWrap}>
            <S.Table>
              <S.Header index={0}>
                <S.LayoutWrap>
                  <S.CurrencySelectCell active={false}>
                    <S.CurrencySelectWrap>
                      {availableViews.map((item) => {
                        return (
                          <S.CurrencySelectItem
                            key={item.id}
                            disabled={
                              !rates[chosenRate] && item.id !== 'crypto'
                            }
                            active={
                              item.label?.toLowerCase() === view?.toLowerCase()
                            }
                            onClick={() => setView(item.label as ViewType)}
                          >
                            {item.currency_icon}
                          </S.CurrencySelectItem>
                        );
                      })}
                    </S.CurrencySelectWrap>
                  </S.CurrencySelectCell>

                  {filteredChoosenCurrency.map((availableCurrency) => {
                    return (
                      <S.CoinCellHeader
                        key={availableCurrency}
                        ref={currencyRef}
                        active={
                          availableCurrency?.toLowerCase() ===
                          cell?.split('-')[1]?.toLowerCase()
                        }
                      >
                        <S.CoinLogo
                          style={{
                            backgroundImage: `url(${getCurrencyLogo(
                              availableCurrency as CurrencyType,
                              appSettings.currencies_icons,
                            )})`,
                          }}
                        />
                        <S.CoinLabel>{availableCurrency}</S.CoinLabel>
                        {newCell === availableCurrency ? (
                          <S.CellAppearance
                            initial={{
                              opacity: 1,
                            }}
                            animate={{
                              opacity: 0,
                              transition: { duration: 4 },
                            }}
                            exit={{ opacity: 0, transition: { duration: 1 } }}
                          />
                        ) : null}
                      </S.CoinCellHeader>
                    );
                  })}
                </S.LayoutWrap>
                <S.CoinCellTotal
                  active={'total' === cell?.split('-')[1]?.toLowerCase()}
                >
                  <S.CoinTotalLabel>
                    TOTAL, {isCryptoTab ? viewInfo.currency : viewInfo.label}
                  </S.CoinTotalLabel>
                </S.CoinCellTotal>
              </S.Header>
              <OverviewRows
                chosenCurrency={chosenCurrency}
                balances={balances}
                availableCurrencies={availableCurrencies}
                initialCurrencies={initialCurrenciesState}
                cell={cell}
                setCell={setCell}
                isCryptoTab={isCryptoTab}
              />
            </S.Table>
            <S.TableFooter
              firefox={
                navigator.userAgent.match(/firefox|fxios/i) ? true : false
              }
            >
              <S.Footer index={0}>
                <S.LayoutWrap>
                  <S.FooterTitleCell active={false}>
                    Total, {isCryptoTab ? 'coins' : chosenRate}
                  </S.FooterTitleCell>

                  {filteredChoosenCurrency.map((availableCurrency) => {
                    const calculatedBalance = balancesByCoin[availableCurrency];

                    const cellID = 'TOTAL-' + availableCurrency;

                    const valueCurrency =
                      viewInfo.id === 'usd_k' || viewInfo.id === 'usd_m'
                        ? viewInfo.id
                        : !isCryptoTab
                        ? chosenRate
                        : availableCurrency;

                    const precision = getCurrencyPrecision(
                      valueCurrency as CurrencyType,
                    );
                    const totalValue = getCurrencyValue({
                      currency: valueCurrency as CurrencyType,
                      value: String(calculatedBalance?.total),
                      minprecision: getCurrencyPrecision(
                        valueCurrency as CurrencyType,
                      ),
                    });
                    const availableValue = getCurrencyValue({
                      currency: valueCurrency as CurrencyType,
                      value: String(calculatedBalance?.available),
                      minprecision: getCurrencyPrecision(
                        valueCurrency as CurrencyType,
                      ),
                    });

                    const totalIsLessThanPrecision =
                      viewInfo.id === 'crypto' &&
                      ['0.', ...Array(precision).fill('0')].join('') ===
                        totalValue;
                    const availableIsLessThanPrecision =
                      viewInfo.id === 'crypto' &&
                      ['0.', ...Array(precision).fill('0')].join('') ===
                        availableValue;

                    return (
                      <S.DataCell
                        key={cellID}
                        onClick={() => setCell(cellID)}
                        active={cell === cellID}
                        total={false}
                      >
                        <Tippy
                          theme="transparent"
                          placement="right"
                          content={
                            isCryptoTab ? (
                              <>
                                <S.HintText>
                                  Total
                                  {(totalIsLessThanPrecision ||
                                    availableIsLessThanPrecision) &&
                                    `: ${_removeTrailingZeros(String(calculatedBalance?.total))} ${valueCurrency}`}
                                </S.HintText>
                                <S.HintText>
                                  Available
                                  {(totalIsLessThanPrecision ||
                                    availableIsLessThanPrecision) &&
                                    `: ${_removeTrailingZeros(String(calculatedBalance?.available))} ${valueCurrency}`}
                                </S.HintText>
                              </>
                            ) : (
                              <>
                                <S.HintText>
                                  Total:{' '}
                                  {calculatedBalance?.total &&
                                  new Decimal(
                                    calculatedBalance?.total,
                                  ).greaterThan(0)
                                    ? getCurrencyValue({
                                        currency: viewInfo.id as CurrencyType,
                                        value: String(calculatedBalance?.total),
                                      })
                                    : 0}
                                  {` ${
                                    !isCryptoTab ? view : availableCurrency
                                  }`}
                                </S.HintText>
                                <S.HintText>
                                  Available:{' '}
                                  {calculatedBalance?.available &&
                                  new Decimal(
                                    calculatedBalance?.available,
                                  ).greaterThan(0)
                                    ? getCurrencyValue({
                                        currency: viewInfo.id as CurrencyType,
                                        value: String(
                                          calculatedBalance?.available,
                                        ),
                                      })
                                    : 0}
                                  {` ${
                                    !isCryptoTab ? view : availableCurrency
                                  }`}
                                </S.HintText>
                              </>
                            )
                          }
                        >
                          <span>
                            <S.Total bold={true}>
                              {viewInfo.id === 'crypto' ? null : (
                                <S.CurrencyLabelWrap>
                                  {viewInfo.currency_label}
                                </S.CurrencyLabelWrap>
                              )}
                              {calculatedBalance?.total &&
                              new Decimal(calculatedBalance?.total).greaterThan(
                                0,
                              )
                                ? totalIsLessThanPrecision
                                  ? '<0.00'
                                  : totalValue
                                : 0}
                              {viewInfo?.divisor_label}
                            </S.Total>
                            <S.Available bold={true}>
                              {viewInfo.id === 'crypto' ? null : (
                                <S.CurrencyLabelWrap>
                                  {viewInfo.currency_label}
                                </S.CurrencyLabelWrap>
                              )}
                              {calculatedBalance?.available &&
                              new Decimal(
                                calculatedBalance?.available,
                              ).greaterThan(0)
                                ? availableIsLessThanPrecision
                                  ? '<0.00'
                                  : availableValue
                                : 0}
                              {viewInfo?.divisor_label}
                            </S.Available>
                          </span>
                        </Tippy>
                      </S.DataCell>
                    );
                  })}
                </S.LayoutWrap>
                <S.TotalCell key={1} total={true} active={false}>
                  {!isCryptoTab && (
                    <S.TotalCellWrapper>
                      <S.Total bold={true}>
                        {viewInfo.id === 'crypto' ? null : (
                          <S.CurrencyLabelWrap>
                            {viewInfo.currency_label}
                          </S.CurrencyLabelWrap>
                        )}
                        {getCurrencyValue({
                          currency: viewInfo.id as CurrencyType,
                          value: String(allTotalValue),
                          minprecision: getCurrencyPrecision(
                            viewInfo.id as CurrencyType,
                          ),
                        })}
                        {viewInfo?.divisor_label?.toLowerCase()}
                      </S.Total>
                      <S.Available bold={true}>
                        {viewInfo.id === 'crypto' ? null : (
                          <S.CurrencyLabelWrap>
                            {viewInfo.currency_label}
                          </S.CurrencyLabelWrap>
                        )}
                        {getCurrencyValue({
                          currency: viewInfo.id as CurrencyType,
                          value: String(allTotalAvailable),
                          minprecision: getCurrencyPrecision(
                            viewInfo.id as CurrencyType,
                          ),
                        })}
                        {viewInfo?.divisor_label?.toLowerCase()}
                      </S.Available>
                    </S.TotalCellWrapper>
                  )}
                </S.TotalCell>
              </S.Footer>
            </S.TableFooter>
          </S.TableWrap>
        </S.TableShadow>
      ) : isAccountsLoading && !exchanges.length ? (
        <Loader />
      ) : (
        <Placeholder height="140px" text="No accounts found" />
      )}
    </S.Wrap>
  );
};

const C = observer(Overview);

export { C as Overview };
