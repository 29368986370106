import { GoogleLogin } from '@react-oauth/google';
import { FC, useContext, useEffect, useState } from 'react';
import { AuthStoreInstance } from 'services';
import AppContext from 'shared/contexts/AppContext';
import { LoginOtp } from '../OtpModal/LoginOtp';

import * as S from './styled';
import { useInput } from 'shared/hooks/useInput';
import Input from '../Input';
import Button from '../Button';
import GoogleAuthButton from './GoogleAuthButton';
import Header from './Header';
import { fi } from 'date-fns/locale';
import { parseError } from 'shared/helpers/errors';
import { ReactComponent as SvgLogo } from '../../assets/icons/views/logo-multik.svg';
import styled from 'styled-components';
import { useNavigate } from 'react-router';
import { useToggle } from 'shared/hooks/useToggle';
import { IconArrow } from 'features/Transfer/view/styled';
import { ArrowIcon } from '../Aside/styled';

const Logo = styled(SvgLogo)`
  margin-left: 5px;
  margin-top: 1px;
  width: 69px;
  height: 25px;
`;

export const Login: FC = () => {
  const { openModal, appSettings } = useContext(AppContext);
  const [showNativeAuth, toggleShowNativeAuth] = useToggle(false);
  const [isLoading, setIsLoading] = useState(false);
  const [urlSet, setUrlSet] = useState(false);
  const email = useInput('');
  const password = useInput('');
  const {
    onSignInSuccess,
    fetchAppTokenWithOtp,
    fetchAppTokenWithEmailAndPassword,
  } = AuthStoreInstance;

  const openEmailLoginModal = () => {
    openModal({
      title: 'Security Verification',
      component: () => (
        <LoginOtp
          email={email.value}
          password={password.value}
          fetchToken={fetchAppTokenWithEmailAndPassword}
        />
      ),
    });
  };

  const openLoginModal = (googleToken: string | undefined) => {
    openModal({
      title: 'Security Verification',
      component: () => (
        <LoginOtp googleToken={googleToken} fetchToken={fetchAppTokenWithOtp} />
      ),
    });
  };

  const onLoginWithEmailAndPassword = async () => {
    try {
      setIsLoading(true);
      await fetchAppTokenWithEmailAndPassword(
        email.value,
        password.value,
        openEmailLoginModal,
      );
    } catch (error) {
      email.setError('');
      password.setError(parseError(error, appSettings));
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <S.LoginWrap
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      exit={{ opacity: 0, transition: { duration: 0.5 } }}
    >
      <Header />
      <S.LogoWrap
        style={{
          height: 'calc(100vh - 160px)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: 1, scale: 1, transition: { duration: 0.5 } }}
        exit={{ opacity: 0, scale: 0, transition: { duration: 0.5 } }}
      >
        <div
          style={{
            color: 'var(--object-primary)',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            // paddingBottom: '1.25rem',
            // marginTop: '5rem',
          }}
        >
          <div
            style={{
              width: '24rem',
              padding: '2rem',
              backgroundColor: 'var(--base)',
              borderRadius: '0.375rem',
              border: '1px solid var(--border)',
              boxShadow:
                '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                fontWeight: '700',
                fontSize: '1.375rem',
                marginBottom: '1.5rem',
                fontFamily: 'var(--font-main)',
                WebkitFontSmoothing: 'auto',
              }}
            >
              Welcome back to
              <Logo />
            </div>
            <GoogleAuthButton
              text="Login with Google"
              loading={isLoading}
              onSuccess={async (response) => {
                try {
                  setIsLoading(true);
                  await onSignInSuccess(response, openLoginModal);
                  window.location.href = '/';
                } catch (error) {
                  setIsLoading(false);
                } finally {
                  setIsLoading(false);
                }
              }}
            />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                margin: '1.5rem 0',
              }}
            >
              <div
                style={{
                  height: '1px',
                  backgroundColor: 'var(--border)',
                  width: '116px',
                }}
              ></div>
              <div style={{ color: 'var(--border)', fontSize: '12px' }}>or</div>
              <div
                style={{
                  height: '1px',
                  backgroundColor: 'var(--border)',
                  width: '116px',
                }}
              ></div>
            </div>
            {!showNativeAuth && (
              <div
                style={{
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                }}
              >
                <div
                  style={{
                    fontFamily: 'var(--font-main)',
                    fontWeight: 'bold',
                    fontSize: '12px',
                    color: 'var(--object-primary)',
                  }}
                  // @ts-ignore
                  onClick={toggleShowNativeAuth}
                >
                  Login with email
                </div>
                <svg
                  style={{ marginLeft: '6px' }}
                  width="8"
                  height="5"
                  viewBox="0 0 8 5"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L4 4L7 1"
                    stroke="var(--object-primary)"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            )}
            {showNativeAuth && (
              <>
                <div>
                  <Input
                    label="Email"
                    removable={true}
                    placeholder="Enter your email"
                    type="text"
                    error={email.error}
                    value={email.value}
                    onChange={email.onChange}
                  />
                </div>
                <div>
                  <Input
                    label="Password"
                    placeholder="Enter your password"
                    type="password"
                    error={password.error}
                    value={password.value}
                    onChange={password.onChange}
                  />
                </div>
                <a
                  href="/reset-password"
                  style={{
                    marginTop: '0.5rem',
                    fontWeight: 'bold',
                    color: 'var(--accent-primary)',
                    fontSize: '12px',
                    cursor: 'pointer',
                    width: 'fit-content',
                  }}
                >
                  Forgot password?
                </a>
                <div style={{ marginTop: '1rem' }}>
                  <Button
                    before={<></>}
                    after={<></>}
                    loading={isLoading}
                    onClick={onLoginWithEmailAndPassword}
                    type="primary"
                    text="Login"
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </S.LogoWrap>
    </S.LoginWrap>
  );
};
