import { memo, useState } from 'react';

import * as S from 'features/Positions/view/styled';
import Tippy from '@tippyjs/react';
import { IconSettings } from 'features/Orders/view/styled';
import { CheckboxLabel } from 'features/Orders/view/components/Filters/styled';
import { Checkbox } from 'shared/components';
import { capitalize } from 'shared/helpers/format';
import { IconSettingsFilled } from 'shared/components/AbstractComponents';

export const PositionsHeader = memo(
  ({ columnsSettings }: { columnsSettings: any }) => {
    const [visible, setVisible] = useState(false);
    const show = () => setVisible(true);
    const hide = () => setVisible(false);
    return (
      <S.PositionsHeaderWrapper>
        <S.Header>
          <S.HeaderRow style={{ paddingRight: '35px' }}>
            {columnsSettings.activeColumns.includes('market_pair') && (
              <S.DateCol style={{ fontFamily: 'var(--font-main)' }}>
                Market pair
              </S.DateCol>
            )}
            {columnsSettings.activeColumns.includes('instrument_type') && (
              <S.DateCol style={{ fontFamily: 'var(--font-main)' }}>
                Market
              </S.DateCol>
            )}
            {columnsSettings.activeColumns.includes('type') && (
              <S.TxCol style={{ fontFamily: 'var(--font-main)' }}>Type</S.TxCol>
            )}
            {columnsSettings.activeColumns.includes('size') && (
              <S.FromCol style={{ fontFamily: 'var(--font-main)' }}>
                Size
              </S.FromCol>
            )}
            {columnsSettings.activeColumns.includes('current_value') && (
              <S.StatusCol style={{ fontFamily: 'var(--font-main)' }}>
                Current value
              </S.StatusCol>
            )}
            {columnsSettings.activeColumns.includes('price_entry') && (
              <S.ToCol style={{ fontFamily: 'var(--font-main)' }}>
                Price entry
              </S.ToCol>
            )}
            {columnsSettings.activeColumns.includes('opening_time') && (
              <S.AmountCol style={{ fontFamily: 'var(--font-main)' }}>
                Opening time
              </S.AmountCol>
            )}
            {columnsSettings.activeColumns.includes('updated_at') && (
              <S.AmountCol style={{ fontFamily: 'var(--font-main)' }}>
                Update time
              </S.AmountCol>
            )}
            {columnsSettings.activeColumns.includes('accumulated_funding') && (
              <S.ConfirmationsCol
                style={{ fontFamily: 'var(--font-main)', minWidth: '130px' }}
              >
                Accumulated funding
              </S.ConfirmationsCol>
            )}
            {columnsSettings.activeColumns.includes('pnl_realized') && (
              <S.ConfirmationsCol
                style={{ fontFamily: 'var(--font-main)' }}
              >
                Realized PnL
              </S.ConfirmationsCol>
            )}
            {columnsSettings.activeColumns.includes('pnl_unrealized') && (
              <S.ConfirmationsCol
                style={{ fontFamily: 'var(--font-main)' }}
              >
                Unrealized PnL
              </S.ConfirmationsCol>
            )}
          </S.HeaderRow>
          <Tippy
            theme="interactive"
            visible={visible}
            offset={[12, 11]}
            placement="bottom-end"
            delay={[0, 0]}
            onClickOutside={hide}
            zIndex={9999999}
            interactive={true}
            content={
              <div>
                <div
                  style={{
                    fontFamily: 'Lato',
                    fontWeight: 'bold',
                    fontSize: '12px',
                    textTransform: 'uppercase',
                    marginBottom: '10px',
                  }}
                >
                  Columns
                </div>
                <div>
                  {columnsSettings.columns.map((column: string) => {
                    const checked =
                      columnsSettings.activeColumns.includes(column);
                    return (
                      <CheckboxLabel
                        style={{ marginBottom: '5px' }}
                        key={column}
                      >
                        <Checkbox
                          checked={checked}
                          onChange={columnsSettings.onChange}
                          type="checkbox"
                          name={column}
                          title={column}
                          value={column}
                        />
                        <span>{capitalize(column.replace('_', ' '))}</span>
                      </CheckboxLabel>
                    );
                  })}
                </div>
              </div>
            }
          >
            {visible ? (
              <IconSettingsFilled
                onClick={hide}
                style={{
                  position: 'absolute',
                  right: '12px',
                  bottom: '10px',
                  stroke: 'var(--accent-primary)',
                  cursor: 'pointer',
                }}
              />
            ) : (
              <IconSettings
                onClick={show}
                style={{
                  position: 'absolute',
                  right: '12px',
                  bottom: '10px',
                  stroke: 'var(--accent-primary)',
                  cursor: 'pointer',
                }}
              />
            )}
          </Tippy>
        </S.Header>
      </S.PositionsHeaderWrapper>
    );
  },
);
