import { useContext, useEffect, useState } from 'react';

import { OrdersHeader } from './OrdersHeader';
import { OrderRow } from './OrderRow';
import { OrdersFilters } from './OrdersFilters';
import { List } from '../shared/List';
import { NotFound } from '../shared/NotFound';
import { ListWrapper } from '../../styled';

import _ from 'lodash';

import { useOrders } from '../hooks/useOrders';
import { OrderExchange } from './OrderExchange';
import { ReactComponent as SvgUsd } from 'shared/assets/icons/views/logo-usd.svg';
import { useFilters } from 'shared/hooks/useFilters';
import AppContext from 'shared/contexts/AppContext';
import { OrdersTabs } from './OrderTabs';
import { useDebounce } from 'shared/hooks/useDebounce';
import { useOptionalColumns } from 'shared/hooks/useOptionalColumns';

export const Orders = () => {
  const { rates } = useContext(AppContext);
  const [tab, setTab] = useState<string>('open_orders');
  const [getFilters, setFilters] = useFilters(`orders_filters_${tab}`);
  const [filters, setCurrentFilters] = useState<any>(getFilters());
  const columnsSettings = useOptionalColumns(
    `orders_${tab}`,
    tab === 'open_orders'
      ? ['market_pair', 'side', 'type', 'size_|_filled', 'price', 'creation_time']
      : [
          'market_pair',
          'side',
          'type',
          'size_|_filled',
          'price',
          'execution_time',
          'status',
          'total',
        ],
  );

  const [search, setSearch] = useState(filters.search ? filters.search : '');
  const { data, isLoading, isInitialLoading, refetch } = useOrders(search, tab);
  useEffect(() => {
    const filters = getFilters();
    setCurrentFilters(filters);
    refetch();
  }, [tab]);

  const onSubmit = () => {
    refetch();
  };

  const [isAsideButtonChecked, setIsAsideButtonChecked] = useState('unset');

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1300);

  useEffect(() => {
    function handleResize() {
      if (isAsideButtonChecked === 'unset') {
        setIsMobile(window.innerWidth < 1300);
      }
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isAsideButtonChecked]);

  const isEmpty = Object.keys(data).length === 0;

  return (
    <>
      <ListWrapper
        style={{ paddingBottom: isEmpty ? '48.5px' : '0px', minWidth: '967px' }}
        isMobile={isMobile}
        isAsideButtonChecked={isAsideButtonChecked}
      >
        <OrdersTabs tab={tab} setTab={setTab} />
        <OrdersHeader tab={tab} columnsSettings={columnsSettings} />
        <List
          columnsSettings={columnsSettings}
          tab={tab}
          isInitialLoading={isInitialLoading}
          isLoading={isLoading || Object.keys(rates || {}).length === 0}
          isFetching={false}
          row={OrderExchange}
          data={data}
        />
        <NotFound paddingTop={false} active={!isLoading && isEmpty}>
          No orders found
        </NotFound>
      </ListWrapper>
      {/* TODO: add otp modal */}
      <OrdersFilters
        tab={tab}
        search={search}
        setSearch={setSearch}
        disabled={isLoading}
        onSubmit={onSubmit}
        isMobile={isMobile}
        isAsideButtonChecked={isAsideButtonChecked}
        setIsAsideButtonChecked={setIsAsideButtonChecked}
        setIsMobile={setIsMobile}
      />
    </>
  );
};
