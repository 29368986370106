import { useQuery } from '@tanstack/react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useFilters } from 'shared/hooks/useFilters';
import { requestGet } from 'shared/axios';
import _ from 'lodash';
import { Order } from 'features/Orders/types';
import { useOptionalColumns } from 'shared/hooks/useOptionalColumns';
const nest = (
  seq: any[],
  keys: Array<string | ((obj: any) => string)>,
): any => {
  if (!keys.length) {
    return seq;
  }
  const [first, ...rest] = keys;
  return _.mapValues(_.groupBy(seq, first), (value) => nest(value, rest));
};

export const stringifyUrl = (url: string, filters: any) => {
  return queryString.stringifyUrl(
    {
      url,
      query: {
        ...filters,
      },
    },
    { arrayFormat: 'separator' },
  );
};
const parseFilters = ({ ...rest }: any) => {
  return {
    ...rest,
  };
};

const getOrders = async (filters: any) => {
  return await requestGet(stringifyUrl('/orders/', { ...filters }));
};

export const useOrders = (search: string, tab: string) => {
  const [getFilters] = useFilters(`orders_filters_${tab}`);
  const location = useLocation();
  const navigate = useNavigate();
  const { data, ...rest } = useQuery(
    ['orders', tab],
    async () => {
      const filters = getFilters();
      const parsedFilters = parseFilters(filters);

      if (tab === 'open_orders') {
        delete parsedFilters.time_closed_at_after;
        delete parsedFilters.time_closed_at_before;
        delete filters.time_closed_at_after;
        delete filters.time_closed_at_before;
        delete parsedFilters.status;
        delete filters.status;
      }

      navigate({
        pathname: location.pathname,
        search: queryString.stringify(
          {
            ...filters,
          },
          {
            arrayFormat: 'separator',
          },
        ),
      });

      const { data } = await getOrders({
        ...parsedFilters,
        is_active: tab === 'open_orders',
        ...(tab === 'open_orders' && {
          status: ['OPEN'],
        }),
      });
      return data;
    },
    {
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      refetchInterval: 15000,
    },
  );

  const grouped = nest(
    data?.results
      ?.map((item: Order) => ({ ...item, ...item.account }))
      .filter((item: Order) =>
        item.pair?.toLowerCase().includes(search?.toLowerCase()),
      )
      .sort((a: Order, b: Order) => a.pair.localeCompare(b.pair)),
    ['exchange', 'name'],
  );
  // TODO: grouping

  return {
    ...rest,
    rows: [],
    data: grouped,
  };
};
