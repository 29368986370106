import { CodeInput, digitsNumber } from 'shared/components/OtpModal/shared';
import * as S from '../styled';

import { useContext, useEffect, useRef, useState } from 'react';
import AppContext from 'shared/contexts/AppContext';
import { EditUserModal } from './EditUserModal';
import { createNumberMask } from 'text-mask-addons';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { requestPatch } from 'shared/axios';
import { removeEmptyValues } from 'shared/helpers/format';
import { parseError } from 'shared/helpers/errors';

const removeUnchangedValues = (oldValues: any, newValues: any) => {
  Object.keys(newValues).forEach((key) => {
    if (newValues[key] === oldValues[key]) {
      delete newValues[key];
    } else if (key === 'period_limit' && !newValues[key]) {
      newValues[key] = oldValues[key];
    }
  });

  if (isNaN(newValues.single_transaction_limit)) {
    newValues.single_transaction_limit = null;
  }

  if (newValues.period_limit && !newValues.period_type) {
    newValues.period_type = oldValues.period_type;
  } else if (newValues.period_type && !newValues.period_limit) {
    newValues.period_limit = oldValues.period_limit;
  }

  return newValues;
};

const USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
});

const parseEmptyValuesToNull = (object: Record<any, any>) => {
  return Object.fromEntries(
    Object.entries(object).map(([_, value]) => [
      _,
      value === '' || value === undefined ? null : value,
    ]),
  );
};

export const ConfirmUserModal = ({
  id,
  email,
  oldValues,
  newValues,
  payments,
}: any) => {
  const timerRef = useRef<number>(-1);
  const { appSettings, openModal } = useContext(AppContext);
  const [otp, setOtp] = useState('');
  const otpRef = useRef(null);
  const queryClient = useQueryClient();
  const [otpErrorText, setOtpErrorText] = useState<string | null>(null);
  const [errorText, setErrorText] = useState<string | null>(null);

  const {
    mutateAsync: updateUser,
    isLoading,
    error,
  } = useMutation({
    mutationFn: async ({ id, newValues, otp }: any) => {
      const cleared = removeUnchangedValues(
        oldValues,
        parseEmptyValuesToNull(newValues),
      );
      const response = await requestPatch(
        `/administration/users/${id}/`,
        cleared,
        {
          headers: {
            ...(otp && { 'X-AUTH-HOTP': otp }),
          },
        },
      );
      return response.data;
    },
    mutationKey: ['updateUser', id],
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: ['users'],
      });
      openModal(null);
    },
  });

  useEffect(() => {
    window.clearTimeout(timerRef.current);
    timerRef.current = window.setTimeout(() => {
      if (otp.length >= digitsNumber) {
        onConfirm();
      }
    }, 900);
  }, [otp]);

  const onOtpReset = () => {
    setOtp('');
    // @ts-ignore
    otpRef?.current?.__clearvalues__();
  };
  const isOtpRequired = appSettings.is_hotp_enabled;

  const isDisabled = isOtpRequired && otp.length !== digitsNumber;

  const onOtpChange = (value: string) => {
    setOtpErrorText(null);
    setOtp(value);
  };

  const onEdit = () => {
    openModal({
      outsideClickClose: false,
      title: 'Edit user',
      width: '406px',
      titleRightText: 'Step 1 of 2',
      component: () => (
        <EditUserModal
          id={id}
          email={email}
          currentValues={oldValues}
          payments={payments}
        />
      ),
    });
  };

  const onConfirm = async () => {
    try {
      await updateUser({ id, newValues, otp });
    } catch (error) {
      const errorMessage = parseError(error, appSettings);
      if (errorMessage.includes('OTP')) {
        setOtpErrorText(errorMessage);
      } else {
        setErrorText(errorMessage);
      }
    }
  };

  return (
    <S.EditUserModalWrapper>
      <S.ModalItem>
        <S.InputLabel>User</S.InputLabel>
        <S.StaticText>{email}</S.StaticText>
      </S.ModalItem>
      {oldValues?.vote_weight !== newValues?.vote_weight && (
        <S.ModalItem>
          <S.InputLabel>New vote weight</S.InputLabel>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <S.OldValue style={{ flexBasis: '20px' }}>
              {oldValues?.vote_weight ? oldValues?.vote_weight : 'Unset'}
            </S.OldValue>
            <S.IconChange style={{ flexBasis: '20px' }} />
            <S.NewValue style={{ flexBasis: '20px' }}>
              {newValues?.vote_weight ? newValues?.vote_weight : 'Unset'}
            </S.NewValue>
          </div>
        </S.ModalItem>
      )}
      {(![null, undefined].includes(oldValues?.single_transaction_limit) ||
        ![null, undefined].includes(newValues?.single_transaction_limit)) &&
        oldValues?.single_transaction_limit !==
          newValues?.single_transaction_limit && (
          <S.ModalItem>
            <S.InputLabel>New single transaction limit</S.InputLabel>
            <S.Changes>
              <S.OldValue>
                {oldValues?.single_transaction_limit &&
                !isNaN(oldValues?.single_transaction_limit)
                  ? USDollar.format(oldValues?.single_transaction_limit)
                  : `${USDollar.format(
                      Number(
                        appSettings?.user_single_transaction_limit_default,
                      ),
                    )} (Default)`}
              </S.OldValue>
              <S.IconChange />
              <S.NewValue>
                {newValues?.single_transaction_limit &&
                !isNaN(newValues?.single_transaction_limit)
                  ? USDollar.format(newValues?.single_transaction_limit)
                  : `${USDollar.format(
                      Number(
                        appSettings?.user_single_transaction_limit_default,
                      ),
                    )} (Default)`}
              </S.NewValue>
            </S.Changes>
          </S.ModalItem>
        )}
      {(oldValues?.period_limit !== newValues?.period_limit ||
        oldValues?.period_type !== newValues?.period_type) && (
        <S.ModalItem>
          <S.InputLabel>New payment limit</S.InputLabel>
          <S.Changes>
            <S.OldValue>
              {USDollar.format(oldValues?.period_limit ?? '0.00')} /{' '}
              {oldValues?.period_type === 'DAY' ? 'Daily' : 'Weekly'}
            </S.OldValue>
            <S.IconChange />
            <S.NewValue>
              {USDollar.format(
                newValues?.period_limit ?? oldValues?.period_limit,
              )}{' '}
              / {newValues?.period_type === 'DAY' ? 'Daily' : 'Weekly'}
            </S.NewValue>
          </S.Changes>
        </S.ModalItem>
      )}
      <S.Divider />
      <div style={{ width: '270px', marginTop: '0px' }}>
        <CodeInput
          bold={false}
          responsive={false}
          title="Multik Authenticator Code"
          ref={otpRef}
          value={otp}
          autoFocus={false}
          required={isOtpRequired}
          onChange={onOtpChange}
          onReset={onOtpReset}
          errorText={otpErrorText}
          showError={true}
        />
      </div>
      <div
        style={{
          color: 'var(--error',
          fontSize: '10px',
          marginTop: '-16px',
          marginBottom: '4px',
        }}
      >
        {errorText}
      </div>
      <S.ActionButtons>
        <S.Cancel onClick={onEdit}>Back</S.Cancel>
        <S.ActionButton disabled={isLoading || isDisabled} onClick={onConfirm}>
          Confirm
        </S.ActionButton>
      </S.ActionButtons>
    </S.EditUserModalWrapper>
  );
};
