import QRCode from 'qrcode.react';
import styled, { css } from 'styled-components';
import MaskedInput from 'react-text-mask';

import { ReactComponent as SvgArrowRight } from 'shared/assets/icons/icon-arrow-right.svg';
import { ReactComponent as SvgArrowRightFull } from 'shared/assets/icons/icon-arrow-right-full.svg';
import { ReactComponent as SvgLock } from 'shared/assets/icons/icon-lock.svg';
import { ReactComponent as SvgEmail } from 'shared/assets/icons/icon-email.svg';
import { ReactComponent as SvgVoteWeight } from 'shared/assets/icons/icon-vote-weight.svg';
import { ReactComponent as SvgCheck } from 'shared/assets/icons/icon-check.svg';
import { ReactComponent as SvgUncheck } from 'shared/assets/icons/icon-uncheck.svg';
import { ReactComponent as SvgLimit } from 'shared/assets/icons/icon-limit.svg';
import { ReactComponent as SvgDevice } from 'shared/assets/icons/icon-device.svg';
import { ReactComponent as SvgUsers } from 'shared/assets/icons/icon-users.svg';
import { ReactComponent as SvgEdit } from 'shared/assets/icons/icon-edit.svg';
import { ReactComponent as SvgAdd } from 'shared/assets/icons/icon-plus-circle.svg';
import { ReactComponent as SvgDropdown } from 'shared/assets/icons/icon-dropdown-arrow.svg';
import { ReactComponent as SvgSearch } from 'shared/assets/icons/icon-search-multik.svg';
import { ReactComponent as SvgXMark } from 'shared/assets/icons/icon-x-mark.svg';
import { motion } from 'framer-motion';
import { Input } from 'shared/components';

export const IconSend = styled(SvgArrowRight)`
  width: 15px;
  height: 20px;
  stroke-width: 2px;
  stroke: white;
`;

export const IconChange = styled(SvgArrowRightFull)`
  width: 20px;
  height: 20px;
  stroke-width: 2px;
  stroke: var(--object-primary);
  margin: 0 20px;
  flex-basis: 40px;
`;

export const InputError = styled.div`
  font-size: 10px;
  line-height: 14.4px;
  margin-top: 6px;
  font-weight: 400;
  font-familty: var(--font-main);
  color: var(--error);
`;

export const ErrorText = styled.div`
  font-size: 12px;
  line-height: 14.4px;
  font-weight: 400;
  font-familty: var(--font-main)
  color: var(--object-primary);

  a {
    color: var(--accent-primary);
  }
`;

export const HeaderIconCss = css`
  width: 20px;
  min-width: 20px;
  height: 20px;
  margin: 0 10px 0 0;
  stroke: var(--accent-primary);

  path {
    stroke: var(--accent-primary);
  }
`;
export const IconLock = styled(SvgLock)`
  ${HeaderIconCss}
`;

export const IconEmail = styled(SvgEmail)`
  ${HeaderIconCss}
`;

export const IconVoteWeight = styled(SvgVoteWeight)`
  ${HeaderIconCss}
`;
export const IconLimit = styled(SvgLimit)`
  ${HeaderIconCss}
  stroke: #0064ff;
`;
export const IconDevice = styled(SvgDevice)`
  ${HeaderIconCss}
`;
export const IconUsers = styled(SvgUsers)`
  ${HeaderIconCss}
`;
export const IconEdit = styled(SvgEdit)`
  ${HeaderIconCss}
`;
export const IconAdd = styled(SvgAdd)`
  ${HeaderIconCss}
  margin: 0 0 0 5px;
  stroke: currentColor;

  path {
    stroke: currentColor;
  }
`;
export const IconClear = styled(SvgXMark)`
  position: absolute;
  right: 10px;
  top: 11px;
  cursor: pointer;
  stroke: var(--placeholder);
`;

export const SetIconCss = css`
  width: 10px;
  min-width: 10px;
  height: 10px;
  margin: 0 5px 0 0;
  stroke: currentColor;
`;

export const CollapseButton = styled.button<{ rotateIcon?: boolean }>`
  padding: 0;
  border: 0;
  background: none;
  transform: rotate(180deg);
  transition: all 0.2s;

  ${(props) =>
    props.rotateIcon &&
    css`
      transform: rotate(0);
    `};
`;
export const Dropdown = styled(SvgDropdown)`
  width: 20px;
  height: 20px;
  stroke: var(--placeholder);
`;

export const IconCheck = styled(SvgCheck)`
  ${SetIconCss}
`;

export const IconUncheck = styled(SvgUncheck)`
  ${SetIconCss}
`;

export const CardWrapper = styled.div<{ isActive?: boolean }>`
  // cursor: pointer;
  box-sizing: border-box;
  padding: 20px;
  background: var(--highlight);
  border: 1px solid var(--border);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 62px;
  transition: max-height 0.25s ease-out;
  overflow: hidden;

  ${(props) =>
    !props.isActive &&
    css`
      max-height: 160px;
      transition: max-height 0.15s ease-in;
    `};
`;
export const PaymentLimitCardWrapper = styled(CardWrapper)`
  grid-area: 2 / 1 / auto / 4;
`;

export const ProfileWrapper = styled(motion.div)`
  width: 100%;
  min-width: 800px;
  // height: 100%;
  min-height: 100vh;
  background: var(--base);
  border-width: 0px 1px;
  border-style: solid;
  border-color: var(--border);
  padding: 20px 20px 60px;
  // display: grid;
  // grid-template-columns: repeat(3, 1fr);
  // grid-auto-rows: min-content;
  // grid-column-gap: 20px;
  // grid-row-gap: 20px;
  position: relative;
`;

export const SearchForm = styled.div`
  position: relative;
  margin-bottom: 5px;
  color: var(--placeholder);
`;

export const IconSearch = styled(SvgSearch)`
  stroke: var(--placeholder);
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 10px;
  margin-top: -10px;
  pointer-events: none;
`;

export const SearchInput = styled.input`
  padding: 10px 40px 10px 40px;
  background: none;
  font-size: 12px;
  border-radius: 5px;
  line-height: 20px;
  width: 100%;
  border: 1px solid var(--border);
  outline: ${(props) =>
    props.value ? 'var(--accent-primary) auto 1px' : 'none'};
  outline: none;

  &:focus-visible,
  &:focus {
    outline: var(--accent-primary) auto 1px;
  }

  color: ${(props) =>
    props.value ? 'var(--object-primary)' : 'var(--object-secondary)'};
`;

export const SearchBar = styled.div`
  position: absolute;
  left: -1px;
  right: -1px;
  top: 100%;
  padding: 10px;
  padding-bottom: 5px;
  background: var(--base);
  border-left: 1px solid var(--border);
  border-right: 1px solid var(--border);
  height: 55px;
`;

export const CardHeader = styled.header`
  display: flex;
  justify-content: space-between;
`;
export const CardHeaderText = styled.div`
  display: flex;
  align-items: center;
  color: var(--object-secondary);
  text-transform: uppercase;
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
  font-family: var(--font-main);
`;
export const CardFooter = styled.footer`
  display: flex;
  justify-content: space-between;
  margin: 10px 0 0 0;
`;
export const CardValue = styled.div<{ isActive?: boolean }>`
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  font-family: var(--font-main)
  color: var(--object-primary);
  margin-top: 20px;

  max-height: 0;
  transition: max-height 0.25s ease-out;
  overflow: hidden;

  ${(props) =>
    !props.isActive &&
    css`
      max-height: 78px;
      transition: max-height 0.15s ease-in;
    `};
`;
export const CardSetWrapper = styled.div`
  display: flex;
  align-items: center;
  font-family: var(--font-main)
  font-size: 9px;
  font-weight: 400px;
  line-height: 11px;
`;

export const CreateModalForm = styled.form`
  min-width: 480px;
`;

export const QrCodeHint = styled.div`
  text-align: center;
  color: var(--object-primary);
  font-size: 12px;
  word-wrap: break-word;
  line-height: 14px;
  font-weight: 400;
  margin-top: 20px;
`;
export const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 0 0 0;
  border-top: 1px solid var(--border);
`;
export const Button = styled.button`
  border: none;
  padding: 13px 20px;
  margin: 0;
  background: var(--accent-primary);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--dark-bg);
  text-transform: uppercase;
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
  font-family: var(--font-main);
  width: fit-content;
  max-width: 131px;
  cursor: pointer;
  transition: all 0.4s;

  &:last-child {
    margin-right: 0;
  }
`;
export const GoToProfileButton = styled(Button)`
  padding: 9px 15px;
  border-radius: 30px;
  float: right;
  max-width: unset;
`;
export const SubmitButton = styled(Button)`
  padding: 9px 15px;
  border-radius: 30px;
  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      background-color: var(--object-secondary);
      color: white;
      cursor: default;
    `};
`;
export const SecondaryButton = styled(Button)`
  background: none;
  color: var(--accent-primary);
`;
export const SlimButton = styled(Button)`
  padding: 3px 5px 3px 10px;
  font-size: 9px;
  max-width: none;
  color: var(--dark-bg);

  ${IconAdd} {
    width: 10px;
    min-width: 10px;
    height: 10px;
  }
`;
const JoinsCss = css`
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translate(0, -50%);
`;
export const JoinButton = styled(SlimButton)`
  ${JoinsCss}
  background: none;
  border: 1px solid var(--border);
  color: var(--success);
`;
export const JoinedButton = styled(SlimButton)`
  ${JoinsCss}
  background: var(--success);
  border: 1px solid var(--success);
  color: var(--dark-bg);
`;

export const OtpButton = styled(Button)``;

export const QrCodeWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
`;
export const PrevButton = styled(SvgDropdown)<{ inactive?: boolean }>`
  width: 10px;
  height: 10px;
  stroke: var(--object-primary);
  opacity: ${({ inactive }) => (inactive ? '0.5' : '1')};
  transform: rotate(90deg);
  cursor: pointer;
`;
export const NextButton = styled(SvgDropdown)<{ inactive?: boolean }>`
  width: 10px;
  height: 10px;
  stroke: var(--object-primary);
  opacity: ${({ inactive }) => (inactive ? '0.5' : '1')};
  transform: rotate(-90deg);
  cursor: pointer;
`;

export const SelectDropdownIcon = styled(SvgDropdown)`
  width: 10px;
  height: 10px;
  stroke: var(--placeholder);
  position: absolute;
  top: calc(50% - 5px);
  left: 40px;
  cursor: pointer;
`;
export const SelectWrap = styled.div`
  position: absolute;
  width: 168.38px;
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  color: var(--object-primary);
  z-index: 999;
  bottom: 20px;
  left: 20px;
`;

export const PagesList = styled.div`
  display: flex;
`;

export const PageButton = styled.div<{ selected?: boolean }>`
  margin: 5px;
  font-size: 12px;
  color: ${({ selected }) =>
    selected ? 'var(--object-primary)' : 'var(--placeholder)'};
  font-family: var(--font-main);
  cursor: pointer;
`;

export const PagesButtons = styled.div<{ pages: number }>`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;
  // width: 200px;
  width: ${({ pages }) =>
    pages > 7 ? '180px' : `calc(20px + ${pages * 21}px)`};
`;

export const GoToPage = styled.div<{ disabled?: boolean }>`
  font-size: 12px;
  color: var(--object-primary);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 127px;
  position: absolute;
  right: 20px;
  bottom: 20px;

  ${(p) =>
    p.disabled &&
    css`
      color: var(--placeholder);
    `}
`;

export const StyledInput = styled(Input)<{ disabled?: boolean }>`
  width: 60px;

  ${(p) =>
    p.disabled &&
    css`
      color: var(--placeholder);
    `}
`;

export const StyledSelect = styled.select`
  z-index: 999;
  height: 30px;
  min-width: 60px;
  cursor: pointer;
  border: 1px solid var(--border);
  box-shadow: none;
  outline: none;
  border-radius: 5px;
  background-color: transparent;
  appearance: none;
  padding: 0 10px;
  font-size: 12px;
  line-height: 14px;
  font-family: var(--font-main);
  position: relative;
`;

export const LinesSelect = styled.div``;

// @ts-ignore
export const QrCodeVisual = styled(QRCode)`
  width: 260px;
  height: 260px;
  background: var(--base);
  padding: 20px;
  border-radius: 10px;
  border: 1px solid var(--border);
`;

export const QrCodeText = styled.p`
  color: var(--object-primary);
  text-align: center;
  word-break: break-all;
  font-size: 24px;
  font-family: Roboto Mono;
  font-weight: 400;
  line-height: 32px;
  margin-top: 20px;
`;

export const PaymentLimitDate = styled.div`
  display: flex;
  cursor: pointer;
`;

// Payment Limit
export const PaymentLimitBarWrap = styled.div`
  width: 100%;
  height: 8px;
  min-height: 8px;
  background: var(--border);
  border-radius: 8px;
  display: flex;
  overflow: hidden;
  margin: 20px 0 0 0;
`;
export const PaymentLimitBar = styled.div`
  height: 100%;
  position: relative;
  width: 100%;
  background: #0064ff;

  &:last-child {
    border-radius: 0 4px 4px 0;
  }
`;
export const PaymentLimitBarValue = styled.div`
  width: 8px;
  height: 8px;
  background: #fff;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  border: 2px solid #0064ff;
  border-radius: 4px;
`;
export const PaymentLimitLabel = styled.div`
  display: flex;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  align-items: baseline;
`;
export const PaymentLimitLabelValue = styled.div`
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  margin: 5px;
`;

// Multisig Groups
export const UsersTableWrapper = styled(CardWrapper)<{
  hasGroups: boolean;
  limit: number;
  isEmpty: boolean;
}>`
  grid-area: 3 / 1 / auto / 4;
  max-height: none;
  cursor: auto;
  position: relative;
  overflow: visible;
  height: ${({ limit, hasGroups, isEmpty }) =>
    isEmpty
      ? '150px'
      : hasGroups
      ? `calc(160px + 42px + ${limit * 40}px)`
      : '180px'};
`;
export const GroupsBody = styled.div`
  padding: 20px 0;
  padding-bottom: 20px;
`;
export const GroupsList = styled.div<{ limit: number }>`
  margin-bottom: 28px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  // min-height: 100px;
  // height: ${({ limit }) =>
    limit ? `calc(42px + ${limit * 40}px)` : '100px'};
  height: calc(42px + ${({ limit }) => limit * 40}px);
`;
const GroupRowCss = css`
  display: flex;
  flex-wrap: no-wrap;
  padding: 15px 20px;
  border-radius: 5px;
  background: var(--base);

  &:nth-child(even) {
    background: none;
  }
`;
export const GroupsRow = styled.div`
  ${GroupRowCss}
  position: relative;
  font-family: var(--font-monospaced);

  &:last-child {
    margin-bottom: 10px;
  }
`;
export const JoinedNumber = styled.div<{ complete: boolean }>`
  color: ${({ complete }) => (complete ? 'var(--success)' : 'var(--warning)')};
`;
export const GroupsCol = styled.div`
  width: 100%;
  min-width: 20%;
  max-width: 20%;
  font-size: 10px;
  line-height: 10px;
  padding: 0 5px;
  color: var(--object-primary);
  display: flex;
  white-space: nowrap;

  svg {
    width: 10px;
    min-width: 10px;
    height: 10px;
    margin: 0 10px 0 0;
    stroke: var(--placeholder);

    path {
      stroke: var(--placeholder);
    }
  }
`;
export const GroupsHeader = styled.header`
  ${GroupRowCss}
  border: 1px solid var(--border);

  ${GroupsCol} {
    font-size: 9px;
    text-transform: uppercase;
    color: var(--accent-primary);
    font-weight: 700;
  }
`;
export const Error = styled.div`
  color: var(--error);
  font-size: 10px;
  line-height: 12px;
  margin-top: 5px;
`;

export const SelectContainer = styled.div`
  position: relative;
  color: var(--object-primary);
  z-index: 999;
  margin: 0;
`;

export const SelectLabelButton = styled.button`
  background-color: #fff;
  z-index: 999;
  border: none;
  color: #111;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.3s ease;
  height: 30px;
  min-width: 60px;
  border: 1px solid var(--border);
  box-shadow: none;
  outline: none;
  border-radius: 5px;
  background-color: transparent;
  appearance: none;
  font-size: 12px;
  line-height: 14px;
  font-family: var(--font-main);
  position: relative;
  color: var(--object-primary);
  padding-left: 10px;
`;

export const DropdownStyle = styled.div<{ isVisible: boolean }>`
  position: absolute;
  top: 0;
  z-index: 999;
  border: 1px solid var(--border);
  left: 0;
  max-height: 40vmax;
  min-width: 100%;
  padding: 0.4rem;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background: var(--base);
  transition: max-height 0.2s ease;
  color: var(--object-primary);
  ${(p) =>
    p.isVisible !== true &&
    css`
      max-height: 40px;
      visibility: hidden;
    `}
`;

export const DropdownItem = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  border-radius: 0.3rem;
  padding: 2px 5px;
  cursor: pointer;
  color: var(--object-primary);
  ${(p) =>
    p.active &&
    css`
      font-weight: 500;
    `}
  &:hover, :focus, :focus:hover {
    background: var(--highlight);
    outline: none;
  }
`;

export const HeaderInfo = styled.div`
  width: 100%;
  margin: 10px 0 30px 0;
  font-size: 12px;
  max-width: 740px;
`;

export const InputLabel = styled.div`
  text-transform: uppercase;
  font-size: 9px;
  color: var(--object-secondary);
  margin-bottom: 10px;
`;

export const ModalItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const StaticText = styled.div`
  font-weight: bold;
  font-size: 18px;
  color: var(--object-primary);
`;

export const EditUserModalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .hoverable-option:hover {
    background: var(--background);
  }
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background: var(--border);
  margin-bottom: 20px;
`;

export const ActionButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ActionButton = styled.div<{ disabled: boolean }>`
  font-weight: bold;
  font-size: 12px;
  color: var(--dark-bg);
  text-transform: uppercase;
  padding: 13px 20px;
  background: ${({ disabled }) =>
    disabled ? 'var(--placeholder)' : 'var(--accent-primary)'};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  border-radius: 30px;
  cursor: pointer;
`;

export const ModalInput = styled(MaskedInput)<{
  error?: boolean;
  whitePlaceholder?: boolean;
}>`
  position: relative;
  font-size: 12px;
  font-weight: bold;
  padding: 10px 12px;
  line-height: 10px;
  width: 100%;
  border: 1px solid ${({ error }) => (error ? 'var(--error)' : 'var(--border)')};
  border-radius: 5px;
  outline: none;
  background: var(--base);
  color: var(--object-primary);

  ${({ whitePlaceholder }) =>
    whitePlaceholder &&
    css`
      ::placeholder {
        color: var(--object-primary);
      }
    `}
`;

export const PaymentLimitInputs = styled.div`
  display: flex;
  align-items: center;
`;

export const Cancel = styled.div`
  font-weight: bold;
  font-size: 12px;
  color: var(--accent-primary);
  text-transform: uppercase;
  cursor: pointer;
`;

export const OldValue = styled.div`
  font-weight: bold;
  font-size: 20px;
  color: var(--object-primary);
  text-decoration: line-through;
  width: 190px;
`;

export const Changes = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const NewValue = styled.div`
  font-weight: bold;
  font-size: 20px;
  color: var(--object-primary);
  width: 190px;
  text-align: right;
`;
