import { action, computed, makeObservable, observable } from 'mobx';
import { IAuthStore } from '../models';
import { requestPost } from 'shared/axios';
import { CredentialResponse, googleLogout } from '@react-oauth/google';
import { ErrorCodes } from 'shared/helpers/errors';
import { SettingsStoreInstance } from 'services/Settings';

class AuthStore implements IAuthStore {
  constructor() {
    makeObservable(this);
    this.appToken = localStorage.getItem('appToken') ?? null;
  }

  @observable appToken: Nullable<string> = null;

  @computed public get isAuth() {
    return this.appToken !== null;
  }

  @observable googleAccessToken: string | undefined = undefined;

  @action.bound public async fetchAppTokenWithEmailAndPassword(
    email: string,
    password: string,
    openModal?: any,
    otp?: string,
  ) {
    try {
      const response = await requestPost(
        '/auth/native/',
        { email, password },
        {
          headers: { noToken: true, ...(otp && { 'X-AUTH-HOTP': otp }) },
        },
      );
      console.log('response', response);
      this.appToken = response.data.jwt_token;
      localStorage.setItem('appToken', response.data.jwt_token);
      localStorage.setItem('refreshToken', response.data.refresh_token);
      window.location.href = '/';
    } catch (e) {
      if (
        SettingsStoreInstance.appSettings.is_hotp_enabled &&
        // @ts-ignore
        (e?.response?.data?.error_code === ErrorCodes.OTP_ERROR ||
          // @ts-ignore
          e?.response?.status === 403)
      ) {
        if (openModal) {
          openModal(email, password);
        } else {
          throw e;
        }
      } else {
        throw e;
      }
    }
  }

  @action.bound public async resetPassword(email: string) {
    const response = await requestPost(
      '/auth/reset-password-request/',
      { email },
      {
        headers: { noToken: true },
      },
    );
    return response.data;
  }

  @action.bound public async setUserPassword(token: string, password: string) {
    const response = await requestPost(
      '/auth/reset-password/',
      { password_token: token, password },
      {
        headers: { noToken: true },
      },
    );
    return response.data;
  }

  @action.bound public async fetchAppTokenWithOtp(
    googleAccessToken: string | undefined,
    otp: string,
  ) {
    try {
      const response = await requestPost(
        '/auth/token/',
        { id_token: googleAccessToken },
        {
          headers: { noToken: true, ...(otp && { 'X-AUTH-HOTP': otp }) },
        },
      );
      this.appToken = response.data.jwt_token;
      localStorage.setItem('appToken', response.data.jwt_token);
      localStorage.setItem('refreshToken', response.data.refresh_token);
    } catch (e) {
      console.log('fetch app token with otp error:', e);
      throw e;
    }
  }

  @action.bound public async fetchAppToken(
    googleAccessToken: string | undefined,
    openModal: any,
  ) {
    try {
      const response = await requestPost(
        '/auth/token/',
        { id_token: googleAccessToken },
        {
          headers: { noToken: true },
        },
      );
      this.appToken = response.data.jwt_token;
      localStorage.setItem('appToken', response.data.jwt_token);
      localStorage.setItem('refreshToken', response.data.refresh_token);
    } catch (e) {
      if (
        SettingsStoreInstance.appSettings.is_hotp_enabled &&
        // @ts-ignore
        (e?.response?.data?.error_code === ErrorCodes.OTP_ERROR ||
          // @ts-ignore
          e?.response?.status === 403)
      ) {
        openModal(googleAccessToken);
        throw e;
      }
    }
  }

  @action.bound public async onSignInSuccess(
    { credential }: CredentialResponse,
    openModal: any,
  ): Promise<void> {
    this.googleAccessToken = credential;
    await this.fetchAppToken(this.googleAccessToken, openModal);
  }

  public async onSignOutSuccess(): Promise<void> {}

  @action.bound public signOut() {
    localStorage.removeItem('appToken');
    localStorage.removeItem('refreshToken');
    this.appToken = null;
    googleLogout();
  }
}

export const AuthStoreInstance = new AuthStore();
